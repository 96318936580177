import { ObjectCommand } from "./command.js";

const SetLineStartCap = Symbol("SetLineStartCap");
export { SetLineStartCap }

export class SetLineStartCapCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetLineStartCap, text: "Start Cap", ...options });
        this.const("value", options.value);
    }

    execute(ctx) {
        this.object.startCapStyle = this.value;
    }
}


const SetLineEndCap = Symbol("SetLineEndCap");
export { SetLineEndCap }

export class SetLineEndCapCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetLineEndCap, text: "End Cap", ...options });    
        this.const("value", options.value);
    }

    execute(ctx) {
        this.object.endCapStyle = this.value;
    }
}

const SetLineShape = Symbol("SetLineShape");
export { SetLineShape }

export class SetLineShapeCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetLineShape, text: "Shape", ...options });
 
        this.const("shape", options.shape);
    }

    execute(ctx) {
        this.object.shape = this.shape;
    }
} 

const SetLineStrokeStyle = Symbol("SetLineStrokeStyle");
export { SetLineStrokeStyle }

export class SetLineStrokeStyleCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetLineStrokeStyle, text: "Stroke Style", ...options });    
        this.const("value", options.value);
    }

    execute(ctx) {
        this.object.strokeStyle = this.value;
    }
}


const SetLineWidth = Symbol("SetLineWidth");
export { SetLineWidth }

export class SetLineWidthCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetLineWidth, text: "Line Width", ...options });    
        this.const("value", options.value);
    }

    execute(ctx) {
        this.object.lineWidth = this.value;
    }
}