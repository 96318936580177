import { Command } from "../base.js";

export class DocumentCommand extends Command {
    constructor(options) {
        const { doc } = options;
        super(options);
        this.prop("doc", doc);
        this.prop("input", null);
    }

    getState(object) {
        return this.state;
    }

    use() {
        this.state = this.getState(this.doc);
        const subs = [this.doc].map(object => object.watch(()=> {
            this.state = this.getState(this.doc);
        }));

        return ()=> {
            subs.forEach(s => s());
        }
    }

}