
const StrokeCollisionWidth = 24;
const StrokeCollisionMargin = 12;

const Top = Symbol('Top');
const Left = Symbol('Left');
const TopLeft = Symbol('TopLeft');
const TopRight = Symbol('TopRight');
const Bottom = Symbol('Bottom');
const Right = Symbol('Right');
const BottomLeft = Symbol('BottomLeft');
const BottomRight = Symbol('BottomRight');

export { Top, Left, Bottom, Right, TopLeft, TopRight, BottomLeft, BottomRight, StrokeCollisionMargin, StrokeCollisionWidth }