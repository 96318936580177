const valueMap = Symbol('valueMap');

export default class Context {
    constructor(initValues) {
        this[valueMap] = new Map(initValues);
    }

    withValue(name, value) {
        return new Context([...this[valueMap], [name, value]]);
    }

    get(name) {
        return this[valueMap].get(name);
    }

    toString() {
        return "Context{...}";
    }
}

Context.background = new Context();
