import { ObjectCommand } from "./command.js";

const MaskOn = Symbol("MaskOn");
export { MaskOn }

export class MaskOnCommand extends ObjectCommand {
    constructor(options) {
        super({ id: MaskOn, text: "Circle", ...options });
    }

    execute(ctx) {
        this.object.mask = "ellipse";
    }
} 
