export default function uuid() {
    if (typeof window !== 'undefined' && window.self && window.self.crypto && window.self.crypto.randomUUID) {
        return self.crypto.randomUUID();
    }

    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
		var r = crypto.getRandomValues(new Uint32Array(1))[0] / Math.pow(2, 32)*16|0, v = c == 'x' ? r : (r&0x3|0x8);
		return v.toString(16);
	});
}
