import { ObjectCommand } from "./command.js";

const ChangeCamera = Symbol("ChangeCamera");
export { ChangeCamera }

export class ChangeCameraCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ChangeCamera, text: "Camera", ...options });

        this.input = ChangeCamera;

        if(this.object) {
            this.participant = this.object.participant;
            this.videoTrack = this.object.videoTrack;
        }
    }

    getState() {
        return this.object && this.object.participant !== undefined ? Enabled : Disabled;
    }

    execute(ctx) {
        this.object.participant = this.participant;
        this.object.videoTrack = this.videoTrack;
    }
} 


const SetCameraTrack = Symbol("SetCameraTrack");
export { SetCameraTrack }

export class SetCameraTrackCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetCameraTrack, text: "Camera Tracks", ...options });

        this.const("videoTrack", options.videoTrack);
        this.const("participant", options.participant);
        this.const("width", options.width);
        this.const("height", options.height);
    }

    executeTransaction(ctx) {
        this.object.videoTrack = this.videoTrack;
        this.object.participant = this.participant;
        if(typeof(this.width) == "number") {
            this.object.width = this.width;
        }
        if(typeof(this.height) == "number") {
            this.object.height = this.height;
        }
    }
}


const Play = Symbol("Play");
export { Play }

export class PlayCommand extends ObjectCommand {
    constructor(options) {
        super({ id: Play, text: "Play", ...options });
    }

    execute(ctx) {
        this.object.playing = true;
    }
}

const Pause = Symbol("Pause");
export { Pause }

export class PauseCommand extends ObjectCommand {
    constructor(options) {
        super({ id: Pause, text: "Pause", ...options });
    }

    execute(ctx) {
        this.object.playing = false;
    }
} 