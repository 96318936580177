import { DocumentCommand } from "./command.js";

const Undo = Symbol("Undo");
export { Undo }


export class UndoCommand extends DocumentCommand {
    constructor(options) {
        super({ id: Undo, text: "Undo", ...options });

        this.cursorAction = "HACK";
    }

    execute(ctx) {
        this.doc.undoManager.undo();
    }
} 

const Redo = Symbol("Redo");
export { Redo }


export class RedoCommand extends DocumentCommand {
    constructor(options) {
        super({ id: Redo, text: "Redo", ...options });

        this.cursorAction = "HACK";
    }

    execute(ctx) {
        this.doc.undoManager.redo();
    }
} 



