import { ObjectCommand } from "./command.js";
import { Frame } from "../../document.js";

const RemoveScene = Symbol("RemoveScene");
export { RemoveScene }

export class RemoveSceneCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveScene, icon: "remove", text: "Remove", ...options });
    }

    execute(ctx) {
        this.object.doc.removeScene(this.object);    
    }
}

const ChangeSceneMode = Symbol("ChangeSceneMode");
export { ChangeSceneMode }

export class ChangeSceneModeCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ChangeSceneMode, text: "Change Scene Mode", ...options });
        this.const("mode", options.mode);
    }

    execute(ctx) {
        this.object.mode = this.mode;
    }
}


const AddSlideToScene = Symbol("AddSlideToScene");
export { AddSlideToScene }

export class AddSlideToSceneCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddSlideToScene, icon: "add", text: "Add Frame", ...options });
    }

    execute(ctx) {

        const scene = this.object;
        const frames = scene.getSortedObjects().filter(x => x instanceof Frame);
        const prevSlide = frames[frames.length - 1];
        const slide = scene.addFrame(undefined, this.position);
        if(!prevSlide) {
            slide.width = scene.width;
            slide.height = scene.height;
        } else {
            slide.width = prevSlide.width;
            slide.height = prevSlide.height;
        }
        
    }
}

const DrawFile = Symbol("DrawFileCommand");
export { DrawFile }

export class DrawFileCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawFile, text: "Draw Image", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.noun = options.noun;
        this.src = options.src;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addFile({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        shape.noun = this.noun;
        shape.src = this.src;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const DrawImage = Symbol("DrawImage");
export { DrawImage }

export class DrawImageCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawImage, icon: "cursor-add", text: "Draw Image", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.noun = options.noun;
        this.src = options.src;
        this.clientID = options.clientID;
        this.clientObjectID = options.clientObjectID,
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addImage({ id: this.clientObjectID, instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        shape.noun = this.noun;
        shape.src = this.src;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const DrawPrompt = Symbol("DrawPrompt");
export { DrawPrompt }

export class DrawPromptCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawPrompt, text: "Draw Prompt", ...options });

        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.clientID = options.clientID;
        this.name = options.name;
        this.response = options.response;
        this.context = options.context;
        this.prompt = options.prompt;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    
    }

    executeTransaction(ctx) {
        const shape = this.object.addPrompt({ instanceID: this.clientID, response: this.response, context: this.context, prompt: this.prompt, createdBy: this.createdBy, createdAt: this.createdAt });
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const UpdatePrompt = Symbol("UpdatePrompt");
export { UpdatePrompt }

export class UpdatePromptCommand extends ObjectCommand {
    constructor(options) {
        super({ id: UpdatePrompt, text: "Draw Prompt", ...options });
        this.response = options.response;
        this.context = options.context;
        this.prompt = options.prompt;
    }

    executeTransaction(ctx) {
        this.object.response = this.response;
        this.object.context = this.context;
        this.object.prompt = this.prompt;
    }
}

const DrawVideo = Symbol("DrawVideo");
export { DrawVideo }

export class DrawVideoCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawVideo, icon: "cursor-add", text: "Draw Video", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.noun = options.noun;
        this.src = options.src;
        this.clientID = options.clientID;
        this.clientObjectID = options.clientObjectID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
        
    }

    executeTransaction(ctx) {
        const shape = this.object.addVideo({ id: this.clientObjectID, instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt });
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        shape.noun = this.noun;
        shape.src = this.src;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const DrawRiveAnimation = Symbol("DrawRiveAnimation");
export { DrawRiveAnimation }

export class DrawRiveAnimationCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawRiveAnimation, icon: "cursor-add", text: "Draw Rive Animation", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.data = options.data;
        this.src = options.src;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addRiveAnimation({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        shape.data = this.data;
        shape.src = this.src;
        if(this.name) {
            shape.name = this.name;
        }
    }
}



const DrawPdf = Symbol("DrawPdf");
export { DrawPdf }

export class DrawPdfCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawPdf, icon: "cursor-add", text: "Draw Pdf", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.noun = options.noun;
        this.src = options.src;
        this.page = options.page;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addPdf({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        shape.noun = this.noun;
        shape.src = this.src;
        shape.page = this.page || 1;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const DrawCode = Symbol("DrawCode");
export { DrawCode }

export class DrawCodeCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawCode, icon: "cursor-add", text: "Draw Code", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addCode("javascript", { instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }
    }
}


const DrawLink = Symbol("DrawLink");
export { DrawLink }

export class DrawLinkCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawLink, icon: "cursor-add", text: "Draw Link", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.clientID = options.clientID;
        this.clientObjectID = options.clientObjectID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;

        this.url = options.url;
        this.title = options.title;
        this.image = options.image;
    }

    executeTransaction(ctx) {
        const shape = this.object.addLink({ id: this.clientObjectID, url: this.url, title: this.title, image: this.image, instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }
    }
}


const DrawShape = Symbol("DrawShape");
export { DrawShape }

export class DrawShapeCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawShape, icon: "cursor-add", text: "Draw Rectangle", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
        
        this.padding = options.padding;        
        this.boxAlignmentX = options.boxAlignmentX;
        this.boxAlignmentY = options.boxAlignmentY;
        this.borderColor = options.borderColor;
        this.borderRadius = options.borderRadius;
        this.borderWidth = options.borderWidth;
        this.background = options.background;
        this.color = options.color;
        this.autoSize = options.autoSize;

        this.text = options.text;
        this.shapeOnly = options.shapeOnly;
        this.fontSize = options.fontSize;
        this.fontName = options.fontName;
    
        this.annotation = options.annotation;
        this.annotationChat = options.annotationChat;
        this.fixedSize = options.fixedSize;

        this.lineHeight = options.lineHeight;
    }

    executeTransaction(ctx) {
        const shape = this.object.addShape(this.shape || "rectangle", { instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }

        if(this.padding !== undefined) {
            shape.padding = this.padding;
        }
        if(this.boxAlignmentX !== undefined) {
            shape.boxAlignmentX = this.boxAlignmentX;
        }
        if(this.boxAlignmentY !== undefined) {
            shape.boxAlignmentY = this.boxAlignmentY;
        }
        if(this.borderColor !== undefined) {
            shape.borderColor = this.borderColor;
        }
        if(this.borderRadius !== undefined) {
            shape.borderRadius = this.borderRadius;
        }
        if(this.borderWidth !== undefined) {
            shape.borderWidth = this.borderWidth;
        }
        if(this.background !== undefined) {
            shape.background = this.background;
        }
        if(this.color !== undefined) {
            shape.color = this.color;
        }
        if(this.autoSize !== undefined) {
            shape.autoSize = this.autoSize;
        }
        if(this.text !== undefined) {
            shape.text = this.text;
        }
        if(this.shapeOnly !== undefined) {
            shape.shapeOnly = this.shapeOnly;
        }
        if(this.fontSize !== undefined) {
            shape.fontSize = this.fontSize;
        }
        if(this.fontName !== undefined) {
            shape.fontName = this.fontName;
        }

        if(this.lineHeight !== undefined) {
            shape.lineHeight = this.lineHeight;
        }


        if(this.annotation != null) {
            shape.annotation = this.annotation;
        }

        if(this.fixedSize != null) {
            shape.fixedSize = this.fixedSize;
        }

        if(this.annotationChat != null) {
            shape.annotationChat = this.annotationChat;
        }
    }
}

const DrawSticky = Symbol("DrawSticky");
export { DrawSticky }

export class DrawStickyCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawSticky, icon: "cursor-add", text: "Draw Sticky", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.clientID = options.clientID;
        this.clientObjectID = options.clientObjectID;
        this.name = options.name;
        this.borderColor = options.borderColor;
        this.background = options.background;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
        this.text = options.text;
        this.lineHeight = options.lineHeight;
        this.padding = options.padding;
    }

    executeTransaction(ctx) {
        const shape = this.object.addSticky(undefined, { id: this.clientObjectID, instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        shape.lineHeight = this.lineHeight ?? 1.3;
        if(this.padding !== null && this.padding !== undefined) {
            shape.padding = this.padding;
        }
        if(this.background) {
            shape.background = this.background;
        }
        if(this.borderColor) {
            shape.borderColor = this.borderColor;
        }
        if(this.name) {
            shape.name = this.name;
        }

        if(this.text) {
            shape.text = this.text;
        }
    }
}


const DrawCameraGrid = Symbol("DrawCameraGrid");
export { DrawCameraGrid }

export class DrawCameraGridCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawCameraGrid, icon: "cursor-add", text: "Draw Camera Grid", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addCameraGrid({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const DrawCamera = Symbol("DrawCamera");
export { DrawCamera }

export class DrawCameraCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawCamera, icon: "cursor-add", text: "Draw Camera", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addLocalCamera({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const DrawScreen = Symbol("DrawScreen");
export { DrawScreen }

export class DrawScreenCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawScreen, icon: "cursor-add", text: "Draw Screen", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
        this.videoTrack = options.videoTrack;
        this.participant = options.participant;
    }

    executeTransaction(ctx) {
        const shape = this.object.addScreen({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }

        if(this.videoTrack) {
            shape.videoTrack = this.videoTrack;
        }
        if(this.participant) {
            shape.participant = this.participant;
        }
    }
}


const DrawLine = Symbol("DrawLine");
export { DrawLine }

export class DrawLineCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawLine, icon: "cursor-add", text: "Draw Line", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.lineWidth = options.lineWidth;
        this.endCapStyle = options.endCapStyle;
        this.strokeStyle = options.strokeStyle;
        this.startCapStyle = options.startCapStyle;
        this.cp1x = options.cp1x;
        this.cp2x = options.cp2x;
        this.cp1y = options.cp1y;
        this.cp2y = options.cp2y;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addLine({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        shape.endCapStyle = this.endCapStyle;
        shape.startCapStyle = this.startCapStyle;
        if(this.lineWidth) {
            shape.lineWidth = this.lineWidth;
        }
        if(this.shape) {
            shape.shape = this.shape;
        }

        if(this.cp1x !== null) {
            shape.cp1x = this.cp1x;
        }
        if(this.cp2x !== null) {
            shape.cp2x = this.cp2x;
        }
        if(this.cp1y !== null) {
            shape.cp1y = this.cp1y;
        }
        if(this.cp2y !== null) {
            shape.cp2y = this.cp2y;
        }
        if(this.name) {
            shape.name = this.name;
        }
        if(this.strokeStyle) {
            shape.strokeStyle = this.strokeStyle;
        }
    }
}


const DrawFrame = Symbol("DrawFrame");
export { DrawFrame }

export class DrawFrameCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawFrameCommand, icon: "cursor-add", text: "Draw Frame", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addFrame({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const AddComment = Symbol("AddComment");
export { AddComment }

export class AddCommentCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddCommentCommand, text: "Comment", ...options });
        this.text = options.text;
        this.transcribed = options.transcribed;
        this.reference = options.reference;
        this.media = options.media;
    }

    executeTransaction(ctx) {
        this.object.addComment({ media: this.media, text: this.text, transcribed: this.transcribed, reference: this.reference });
    }
}



const AddIFrame = Symbol("AddIFrame");
export { AddIFrame }

export class DrawIFrameCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawIFrameCommand,  text: "Draw IFrame", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.clientID = options.clientID;
        this.src = options.src;
        this.alt = options.alt;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
        this.name = options.name;
    }


    executeTransaction(ctx) {
        const shape = this.object.addIFrame({ instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        shape.src = this.src;
        if(this.alt) {
            shape.alt = this.alt;
        }
        if(this.name) {
            shape.name = this.name;
        }
        
    }
}


const DrawText = Symbol("DrawText");
export { DrawText }

export class DrawTextCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawTextCommand,  text: "Draw Text", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.text = options.text;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }


    executeTransaction(ctx) {
        const shape = this.object.addText(this.text, { instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }
    }
}

const DrawPage = Symbol("DrawPage");
export { DrawPage }

export class DrawPageCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DrawPageCommand, icon: "cursor-add", text: "Draw Page", ...options });
        this.top = options.top;
        this.left = options.left;
        this.width = options.width;
        this.height = options.height;
        this.shape = options.shape;
        this.clientID = options.clientID;
        this.name = options.name;
        this.createdBy = options.createdBy;
        this.createdAt = options.createdAt;
    }

    executeTransaction(ctx) {
        const shape = this.object.addText("", { instanceID: this.clientID, createdBy: this.createdBy, createdAt: this.createdAt});
        shape.name = "Page";
        shape.padding = 40;
        shape.fontSize = 20;
        shape.pageMode = true;
        shape.color = "#000000";
        shape.allowEmbeds = true;
        shape.background = { color: "#ffffff" };
        shape.x = this.left;
        shape.y = this.top;
        shape.width = this.width;
        shape.height = this.height;
        if(this.name) {
            shape.name = this.name;
        }
    }
}