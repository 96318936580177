import { ObjectCommand } from "./command.js";

const NextImageCommand = Symbol("NextImageCommand");
export { NextImageCommand }

export class NextImageCommandCommand extends ObjectCommand {
    constructor(options) {
        super({ id: NextImageCommand, text: "Next Image", ...options });
    }

    execute(ctx) {
        this.object.nextImage();
    }
} 

const PrevImageCommand = Symbol("NextImageCommand");
export { PrevImageCommand }

export class PrevImageCommandCommand extends ObjectCommand {
    constructor(options) {
        super({ id: PrevImageCommand,  text: "Previous Image", ...options });
    }

    execute(ctx) {
        this.object.prevImage();
    }
} 


const NextPage = Symbol("NextPage");
export { NextPage }

export class NextPageCommand extends ObjectCommand {
    constructor(options) {
        super({ id: NextPage, text: "Next Page", ...options });
    }


    execute(ctx) {
        this.object.nextPage();
    }
} 


const PrevPage = Symbol("PrevPage");
export { PrevPage }

export class PrevPageCommand extends ObjectCommand {
    constructor(options) {
        super({ id: PrevPage, text: "Previous Page", ...options });
    }

    execute(ctx) {
        this.object.prevPage();
    }
} 
