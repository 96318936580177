import { ObjectCommand, ObjectsCommandGroup } from "./command.js";


const SetReaction = Symbol("SetReaction");
export { SetReaction }


export class SetReactionCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetReaction, text: "Set Reaction", ...options });
        this.reaction = options.reaction || "👍"; // TODO: use emojis?
        this.createdBy = options.createdBy;
    }

    execute(ctx) {
        this.object.setReaction({ reaction: this.reaction, createdBy: this.createdBy });
    }
}

const RemoveReaction = Symbol("RemoveReaction");
export { RemoveReaction }

export class RemoveReactionCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveReaction,  text: "None", ...options });
        this.createdBy = options.createdBy;
    }

    execute(ctx) {
        this.object.removeReaction(this.createdBy);
    }
}


