/**
 * Position Index - requirement: insertion, move or removal of items within a collection
 * should not result in update/reindexing of other items.
 *
 * Position index is sortable string encoding positive decimal fraction between 0(END) and 1(START)
 *
 */
// insert into position between 1/2
//
// String-sortable 64 chars
const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz|~';

export const START = [];
export const END = [];

const bufferToString = buffer => {
    let s1, s2, s3, s4;
    let str = '';

    for (let i = 0; i < buffer.length; i += 3) {
        s1 = buffer[i] >> 2;
        s2 = (buffer[i] & 3) << 4;
        s3 = 0;
        s4 = 0;

        if (buffer[i + 1] !== undefined) {
            s2 += (buffer[i + 1] >> 4);
            s3 = ((buffer[i + 1] & 15) << 2)
        }

        if (buffer[i + 2] !== undefined) {
            s3 += (buffer[i + 2] >> 6);
            s4 = (buffer[i + 2] & 63);
        }

        str = str.concat(chars[s1], chars[s2], chars[s3], chars[s4]);
    }

    return str.replace(/0+$/, '');
};

const stringToBuffer = str => {
    let i1, i2, i3, i4, b1, b2, b3;
    let buf = [];

    for (let i = 0; i < str.length; i += 4) {
        i1 = chars.indexOf(str[i]);
        i2 = chars.indexOf(str[i + 1]);
        i3 = chars.indexOf(str[i + 2]);
        i4 = chars.indexOf(str[i + 3]);
        b2 = 0;
        b3 = 0;

        b1 = i1 << 2;

        if (i2 > 0) {
            b1 += i2 >> 4;
            b2 = (i2 & 15) << 4;
        }

        if (i3 > 0) {
            b2 += i3 >> 2;
            b3 = (i3 & 3) << 6;
        }

        if (i4 > 0) {
            b3 += i4;
        }

        buf.push(b1, b2, b3);
    }

    while (buf[buf.length - 1] === 0) {
        buf.pop();
    }

    return buf;
};

const ones = new Set([255, 127, 63, 31, 15, 7, 3, 1]);
const hasTralingOnes = x => ones.has(x);
const reduceTrailingZeros = x => ([255, 254, 252, 248, 240, 224, 192, 128].find(v => x >= v)) || 0;
const mostSignificatBits = x => {
    let acc = 0;

    for (const i of [128, 64, 32, 16, 8, 4, 2, 1]) {
        if (acc > 0 && (x & i) === 0) {
            return acc;
        }

        if (x & i) {
            acc += i;
        }
    }

    return acc;
};

const initBuf = (diff) => {
    const buf = [];
    const len = diff.length;
    let i = 0;

    // push leading 0s
    while (i < len && diff[i] === 0) {
        buf.push(0);
        i++;
    }
    // push trailing 1s
    if (i < len) {
        if (hasTralingOnes(diff[i])) {
            buf.push(diff[i]);
            i++;
            while (i < len && diff[i] === 255) {
                buf.push(255);
                i++;
            }
            if (i < len ) {
                const v = reduceTrailingZeros(diff[i]);

                if (v > 0) {
                    buf.push(v);
                }
            }
        } else {
            buf.push(mostSignificatBits(diff[i]));
        }
    }

    return buf;
};

export const between = (a, b) => {
    if (typeof a === "string") {
        a = stringToBuffer(a);
    }
    if (typeof b === "string") {
        b = stringToBuffer(b);
    }

    if (a.length === 0) {
        a = START;
    }
    if (b.length === 0) {
        b = END;
    }

    if (a === START && b === END) {
        return bufferToString([128]);
    }

    // Prevent our worst case when inserting at the beginning
    if (a === START) {
        let b1 = b[1] || 0;

        if (b[0] !== 0 || b1 !== 0) {
            if (b1) {
                return bufferToString([b[0], b1 - 1]);
            } else {
                return bufferToString([b[0] - 1, 255]);
            }
        }
    }

    // Prevent our worst case when inserting at the end
    if (b === END) {
        let a1 = a[1] || 0;

        if (a[0] !== 255 || a[1] !== 255) {
            if (a1 === 255) {
                return bufferToString([a[0] + 1]);
            } else {
                return bufferToString([a[0], a1 + 1]);
            }
        }
    }

    const len = Math.min(a.length, b.length);
    const buf = initBuf(a.length > b.length ? a.slice(b.length) : b.slice(a.length));
    let remainder = 0;

    for (let i = len - 1; i >= 0; i--) {
        const v = (a[i] || 0) + (b[i] || 0) + remainder;
        remainder = v > 255 ? 1 : 0;

        buf.unshift(remainder ? (v - 256) : v);
    }

    if (b === END) {
        remainder = 1;
    }

    for (let i = 0; i < buf.length; i++) {
        const v = Math.floor(buf[i] / 2) + (remainder ? 128 : 0);

        remainder = buf[i] % 2;

        buf[i] = v;
    }

    if (remainder) {
        buf.push(128);
    }

    return bufferToString(buf);
}
