import { useEffect, useRef } from "react";
import { useState } from "react";
import { BaseDocument, Document, SettingsDocument, ChatDocument } from "timu-slide/document.js";
import Context from "timu-context";
import * as allDocumentCommands from "timu-slide/commands/document/all";
import * as allCommands from "timu-slide/commands/object/all";

export default function Index({ offline }) {
    
    const meetingID = window.location.hash.substr(1);

    useEffect(()=> {
        const defer = [];

        let DocType;
        if(meetingID.startsWith("core:powerboard/")) {
            DocType = Document;
        } else if(meetingID.startsWith("core:powerboard-settings/")) {
            DocType = SettingsDocument;
        } else if(meetingID.startsWith("core:powerboard-chat/")) {
            DocType = ChatDocument;
        }
        const doc =  new DocType();

        const hashChange = () => setMeetingID(window.location.hash.substr(1));
        
        addEventListener('hashchange', hashChange);
        defer.push(() => removeEventListener('hashchange', hashChange));
        if(meetingID) {
           
            window.__executeTimuCommand = (cmd) => {
                console.log("Executing command", cmd);
                const C = allCommands[cmd.type] || allDocumentCommands[cmd.type];
                if(!C) {
                    throw new Error("Unknown command "+cmd.type);
                }

                if(cmd.objects) {
                    const objects = cmd.objects.map(cmd => {
                        const object = doc.getObjectInstance(cmd.objectID, cmd.instanceID);
                        if(!object) {
                            throw("Unable to locate object instance "+cmd.objectID +"/" + cmd.instanceID);
                        }
                        return object;
                    });

                    new C({ ...cmd, objects }).execute(Context.background);
                } else if(cmd.objectID && cmd.instanceID) {
                    const object = doc.getObjectInstance(cmd.objectID, cmd.instanceID);
                    if(!object) {
                        console.error("Unable to locate object instance "+cmd.objectID, cmd.instanceID);
                        return;
                    }

                    new C({ ...cmd, object }).execute(Context.background);
                } else if(cmd.objectID) {                    
                    const object = cmd.objectID == "root" ? doc._root : doc.instantiateObject(cmd.objectID);                    
                    new C({ ...cmd, object }).execute(Context.background);
                } else {
                    new C({ ...cmd, object: doc, doc: doc }).execute(Context.background);
                }

                window.__lastTimuCommand = cmd;
            };
           
            doc.onReady = ()=> {
              
                if(typeof(timuHostChannel) == "undefined") {
                    const messageHandler = (event)=> {
                        const msg = JSON.parse(event.data);
                        if(msg.type == "sync") {

                            if(!doc.isSynchronizing) {                            
                                console.log("Starting sync...");
                                const hostChannel = typeof(timuHostChannel) == "undefined"? event.source : timuHostChannel;
                                hostChannel.postMessage(JSON.stringify({ instance: msg.instance, doc: meetingID, type:"ready"}), "*");

                                doc.sync((m) => {
                                    console.log("sync-message", m);
                                    hostChannel.postMessage(JSON.stringify({ instance: msg.instance, doc: meetingID, ...m}), "*");
                                });

                                var awareness = Object.fromEntries(doc.awareness.getStates());
                                hostChannel.postMessage(JSON.stringify({ instance: msg.instance, doc: meetingID, changes: [{action:"init-awareness", states: awareness, me: doc.me() }]}), "*");
                                doc.awareness.on('update', changes => {
                                    const states = doc.awareness.getStates();
                                    const added = changes.added.map(c => ({ name: c, value: states.get(c) }));
                                    const updated = changes.updated.map(c => ({ name: c, value: states.get(c) }));
                                    const removed = changes.removed;
                                    hostChannel.postMessage(JSON.stringify({ instance: msg.instance, doc: meetingID, changes: [{action:"awareness-update", changes: { added, updated, removed }}]}), "*");
                                });
                            }

                        } else if(msg.type == "exec") {
                            __executeTimuCommand(msg.command);
                        }
                    }
                    window.addEventListener("message", messageHandler, false);
                    console.log("Registering sync...");
                    defer.push(()=>window.removeEventListener("message", messageHandler));
                } else {
                    const hostChannel = timuHostChannel;
                    hostChannel.postMessage(JSON.stringify({doc: meetingID, type:"ready"}), "*");

                    doc.sync((msg) => {
                        console.log("sync-message", msg);
                        hostChannel.postMessage(JSON.stringify({doc: meetingID, ...msg}), "*");
                    });

                    var awareness = Object.fromEntries(doc.awareness.getStates());
                    hostChannel.postMessage(JSON.stringify({doc: meetingID, changes: [{action:"init-awareness", states: awareness, me: doc.me()}]}), "*");
                    doc.awareness.on('update', changes => {
                        const states = doc.awareness.getStates();
                        const added = changes.added.map(c => ({ name: c, value: states.get(c) }));
                        const updated = changes.updated.map(c => ({ name: c, value: states.get(c) }));
                        const removed = changes.removed;
                        hostChannel.postMessage(JSON.stringify({doc: meetingID, changes: [{action:"awareness-update", changes: { added, updated, removed }}]}), "*");
                    });
                }
                
    
            };
           
            doc.participant = new Date().toISOString();

            doc.connectWebsocket("/api/docs/"+meetingID); //, Context.background.get("apiRoot").replace("http","ws"));

            window.__timuDoc = doc;
            
        }
        return () => {
            defer.forEach((fn)=>fn())
            doc.provider.disconnect();
        }
    }, [meetingID]);


    return <div>
        Listening to doc {meetingID}
    </div>

}