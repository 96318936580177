import { Command } from "../base.js";
import { Document, BaseDocument } from "../../document.js";

export class ObjectsCommand extends Command {
    constructor(options) {
        const { objects } = options;
        super(options);        
        this.const("objects", objects);
    }
}

export class ObjectCommand extends ObjectsCommand {
    constructor(options) {
        const { object } = options;
        super({ ...options, objects: options.object ? [ object ] : options.objects });        
        this.prop("input", null);
    }

    get object() {
        return this.objects ? this.objects[0] : null;
    }

    execute(ctx) {
        ((this.object instanceof BaseDocument) ? this.object : this.object.doc)._y.transact(()=> {
            this.executeTransaction(ctx);
        });
    }
}

export class ObjectsCommandGroup extends ObjectsCommand {
    constructor(options) {
        super(options);

        this.const("commands", options.commands || []);
    }

    clone(props) {
        return new this.constructor(Object.assign({}, this, { commands: this.commands.map(c => c ? c.clone({ objects: this.objects }) : c ) }, props));
    }
}