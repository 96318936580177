import { ObjectCommand, ObjectsCommand } from "./command.js";

const GroupObjects = Symbol("GroupObjects");
export { GroupObjects }

export class GroupObjectsCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: GroupObjects, text: "Group Objects", ...options });
    }

    execute(ctx) {
        const slide = this.objects[0].parent;
        const widget = slide.addWidget();
       

        const minX = this.objects.reduce((p,c) => Math.min(c.bounds.x,p), this.objects[0].bounds.x);
        const minY = this.objects.reduce((p,c) => Math.min(c.bounds.y,p), this.objects[0].bounds.y);

        const maxX = this.objects.reduce((p,c) => Math.max(c.bounds.x + c.bounds.width,p), this.objects[0].bounds.x);
        const maxY = this.objects.reduce((p,c) => Math.max(c.bounds.y + c.bounds.height,p), this.objects[0].bounds.y);

        const width = maxX - minX;
        const height = maxY - minY;

        widget.x = minX;
        widget.y = minY;

        widget.template.width = widget.width = width;
        widget.template.height = widget.height = height;
        widget.template.background = { color: "transparent" };

        this.objects.forEach(o => {
            const newObject = widget.template.move(o);
            newObject.x -= minX;
            newObject.y -= minY;
        });
    }
} 


const MergeIntoGroup = Symbol("MergeIntoGroup");
export { MergeIntoGroup }

export class MergeIntoGroupCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: MergeIntoGroup, text: "Merge into Group", ...options });
    }

    execute(ctx) {
        const widget = this.objects.find(x => x.type == "widget");
        this.objects.forEach(o => {
            if(o != widget) {
                widget.template.move(o);
            }
        });
    }
} 



const UngroupObjects = Symbol("UngroupObjects");
export { UngroupObjects }

export class UngroupObjectsCommand extends ObjectCommand {
    constructor(options) {
        super({ id: UngroupObjects, text: "Ungroup", ...options });
    }

    execute(ctx) {
        const scaleX = this.object.width / this.object.template.width;
        const scaleY = this.object.height / this.object.template.height;
        this.object.layout = null;
        this.object.template.getSortedObjects().forEach(o => {
            const newObject = this.object.parent.move(o);
            newObject.x *= scaleX;
            newObject.y *= scaleY;
            newObject.x += this.object.x;
            newObject.y += this.object.y;
            newObject.width *= scaleX;
            newObject.height *= scaleY;
        });
        this.object.delete();
    }
} 



const LayoutColumns = Symbol("LayoutColumns");
export { LayoutColumns }

export class LayoutColumnsCommand extends ObjectCommand {
    constructor(options) {
        super({ id: LayoutColumns, text: "Layout Cols", ...options });
    }

    execute(ctx) {
        this.object.layout = "cols";
    }
}

const LayoutRows = Symbol("LayoutRows");
export { LayoutRows }

export class LayoutRowsCommand extends ObjectCommand {
    constructor(options) {
        super({ id: LayoutRows, text: "Layout Rows", ...options });
    }

    execute(ctx) {
        this.object.layout = "rows";
    }
}

const LayoutGrid = Symbol("LayoutGrid");
export { LayoutGrid }

export class LayoutGridCommand extends ObjectCommand {
    constructor(options) {
        super({ id: LayoutGrid, text: "Layout Grid", ...options });
    }

    execute(ctx) {
        this.object.layout = "grid";
    }
}


const MaskGroupObjects = Symbol("MaskGroupObjects");
export { MaskGroupObjects }

export class MaskGroupObjectsCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: MaskGroupObjects, text: "Mask Objects", ...options });
    }

    execute(ctx) {
        const slide = this.objects[0].slide;
        const widget = slide.addWidget();
       

        const minX = this.objects.reduce((p,c) => Math.min(c.x,p), this.objects[0].x);
        const minY = this.objects.reduce((p,c) => Math.min(c.y,p), this.objects[0].y);

        const maxX = this.objects.reduce((p,c) => Math.max(c.x + c.width,p), this.objects[0].x);
        const maxY = this.objects.reduce((p,c) => Math.max(c.y + c.height,p), this.objects[0].y);

        const width = maxX - minX;
        const height = maxY - minY;

        widget.x = minX;
        widget.y = minY;

        widget.template.width = widget.width = width;
        widget.template.height = widget.height = height;
        widget.template.background = { color: "transparent" };

        widget.maskGroup = true;

        this.objects.forEach(o => {
            const newObject = widget.template.move(o);
            newObject.x -= minX;
            newObject.y -= minY;
        });
    }
} 

