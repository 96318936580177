import { ObjectCommand } from "./command.js";

const SetBorderColor = Symbol("SetBorderColor");
export { SetBorderColor }

export class SetBorderColorCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetBorderColor, text: "Border Color", ...options });
      
        this.const("color", options.color);
    }

    executeTransaction(ctx) {
        const color = this.color;
        if(color) {
            this.object.borderColor = color;
        } else {
            this.object.borderColor = null;
        }
        if(!this.object.borderWidth) {
            this.object.borderWidth = 1;
        }
    }
}


const SetBorderWidth = Symbol("SetBorderWidth");
export { SetBorderWidth }

export class SetBorderWidthCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetBorderWidth, text: "Border Width", ...options });

        this.borderWidth = options.borderWidth;
    }

    execute(ctx) {        
       this.object.borderWidth = this.borderWidth;
    }
}

const SetBorderRadius = Symbol("SetBorderRadius");
export { SetBorderRadius }

export class SetBorderRadiusCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetBorderRadius, text: "Border Radius", ...options });
        this.borderRadius = options.borderRadius;
    }


    execute(ctx) {
        this.object.borderRadius = this.borderRadius;
    }
}


const SetBackgroundGradient = Symbol("SetBackgroundGradient");
export { SetBackgroundGradient }

export class SetBackgroundGradientCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetBackgroundGradient, text: "Background Gradient", ...options });

        this.const("gradient", options.gradient);
    }

    execute(ctx) {
        this.object.background = { gradient: this.gradient };
    }
}



const SetBackgroundColor = Symbol("SetBackgroundColor");
export { SetBackgroundColor }

export class SetBackgroundColorCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetBackgroundColor, text: "Background Color", ...options });

        this.const("color", options.color);
    }

    execute(ctx) {
        this.object.background = { color: this.color };
    }
}



const SetBackgroundImage = Symbol("SetBackgroundImage");
export { SetBackgroundImage }

export class SetBackgroundImageCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetBackgroundImage, text: "Background  Image", ...options });

        this.const("image", options.image);
    }

    execute(ctx) {
        this.object.background = { image: this.image };
    }
}



const SetBackgroundGrid = Symbol("SetBackgroundGrid");
export { SetBackgroundGrid }

export class SetBackgroundGridCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetBackgroundGrid, text: "Background Grid", ...options });

        this.const("width", options.width);
        this.const("height", options.height);
        this.const("spacing", options.spacing);
        this.const("lineColor", options.lineColor);
        this.const("background", options.background);
    }

    execute(ctx) {
        this.object.background = {
            grid: {
                width: this.width,
                height: this.height,
                spacing: this.spacing,
                lineColor: this.lineColor,
                background: this.background,
            } 
        };
    }
}

const SetColor = Symbol("SetColor");
export { SetColor }

export class SetColorCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetColor, text: "Text Color", ...options });

      
        this.const("color", options.color);
    }

    execute(ctx) {
        const color = this.color;
        if(color) {
            this.object.color = color;
        } else {
            this.object.color = "#000";
        }
    }
}