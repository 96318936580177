import { ObjectCommand } from "./command.js";

const EditWidget = Symbol("EditWidget");
export { EditWidget }

export class EditWidgetCommand extends ObjectCommand {
    constructor(options) {
        super({ id: EditWidget, text: "Edit Widget", ...options });
    }

    execute(ctx) {
        ctx.get("setFocusedSlide")(this.object.template);
    }
} 

