import { ObjectCommand } from "./command.js";

const RemoveLayer = Symbol("RemoveLayer");
export { RemoveLayer }

export class RemoveLayerCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveLayer, text: "Remove Layer", ...options });
    }

    execute(ctx) {
        this.object.remove();
    }
} 

const HideLayer = Symbol("HideLayer");
export { HideLayer }

export class HideLayerCommand extends ObjectCommand {
    constructor(options) {
        super({ id: HideLayer, text: "Hide Layer", ...options });
    }

    execute(ctx) {
        this.object.hidden = true;
    }
} 

const ShowLayer = Symbol("ShowLayer");
export { ShowLayer }

export class ShowLayerCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ShowLayer, text: "Show Layer", ...options });
    }

    execute(ctx) {
        this.object.hidden = false;
    }
} 



const Move = Symbol("Move");
export { Move }

export class MoveCommand extends ObjectCommand {
    constructor(options) {
        super({ id: Move, text: "Move", ...options });
        this.const("x", options.x);
        this.const("y", options.y);
        this.const("parentID", options.parentID);
        this.const("parentInstanceID", options.parentInstanceID);
    }

    executeTransaction(ctx) {
       if(this.parentID) {
            const parent = this.object.doc.getObjectInstance(this.parentID, this.parentInstanceID);
            const docPoint = this.object.toDocPoint();
            const object = parent.move(this.object);
 /*
            docPoint.x += this.x;
            docPoint.y += this.y;

            const localPoint = parent.fromDocPoint(docPoint);*/

            object.x = this.x;
            object.y = this.y;
       }
    }
}

const SetSketches = Symbol("SetSketches");
export { SetSketches }

export class SetSketchesCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetSketches, text: "Set Sketches", ...options });       
        this.const("sketches", options.sketches);
    }

    executeTransaction(ctx) {
        this.object.sketch.set(this.object.doc.awareness.clientID.toString(), this.sketches);
    }
}

const SetAutoSize = Symbol("SetAutoSize");
export { SetAutoSize }

export class SetAutoSizeCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetAutoSize, text: "SetAutoSize", ...options });
        this.const("autoSize", options.autoSize);
    }

    getState() {
        return this.object ? Enabled : Disabled;
    }

    executeTransaction(ctx) {      
        this.object.autoSize = this.autoSize;
    }
}

const SetBoxAlignment = Symbol("SetBoxAlignment");
export { SetBoxAlignment }

export class SetBoxAlignmentCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetBoxAlignment, text: "SetBoxAlignment", ...options });
        this.const("boxAlignmentX", options.boxAlignmentX);
        this.const("boxAlignmentY", options.boxAlignmentY);
    }

    getState() {
        return this.object ? Enabled : Disabled;
    }

    executeTransaction(ctx) {      
        this.object.boxAlignmentX = this.boxAlignmentX;
        this.object.boxAlignmentY = this.boxAlignmentY;
    }
}


const Reposition = Symbol("Reposition");
export { Reposition }

export class RepositionCommand extends ObjectCommand {
    constructor(options) {
        super({ id: Reposition, text: "Reposition", ...options });
        this.const("x", options.x);
        this.const("y", options.y);
        this.const("width", options.width);
        this.const("height", options.height);
    }

    getState() {
        return this.object ? Enabled : Disabled;
    }

    executeTransaction(ctx) {
      
        this.object.x = this.x;
        this.object.y = this.y;
        this.object.width = this.width;
        this.object.height = this.height;
    
    }
}

const Rename = Symbol("Rename");
export { Rename }

export class RenameCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RenameCommand, text: "Rename", ...options });
        this.const("name", options.name);
    }

    getState() {
        return this.object ? Enabled : Disabled;
    }

    executeTransaction(ctx) {      
        this.object.name = this.name;    
    }
}