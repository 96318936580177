window.__allErrors = [];
addEventListener("error", (event) => {
    window.__allErrors.push(event);
});

import bootstrap from "./editor/bootstrap";

window.printChildren = function printChildren(o, level = 0) {
    const indent = "-".repeat(level);
    o.children.forEach((c) => { console.log(indent + c.constructor.name);  printChildren(c, level + 2) });
}

bootstrap(document.getElementById("root"));
