import { ObjectCommand } from "./command.js";

const ApplyTextDelta = Symbol("ApplyTextDelta");
export { ApplyTextDelta }

export class ApplyTextDeltaCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ApplyTextDelta, text: "Text", ...options });

        this.const("delta", options.delta);
        this.const("remote", options.remote);
    }

    execute(ctx) {

        if(!this.remote) {
            this.object._text.applyDelta(this.delta);
        } else {
            // HACK: We need to prevent sending back our own changes over the wire         
            window.__applyingLocalChange = true;
            try {
                this.object._text.applyDelta(this.delta);
            } finally {
                window.__applyingLocalChange = false;
            }
        }
    }
}

const SetFontName = Symbol("SetFontName");
export { SetFontName }

export class SetFontNameCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetFontName, text: "Font", ...options });
        this.fontName = options.value;
    }

    execute(ctx) {
       this.object.fontName = this.fontName;
    }
}


const SetFontSize = Symbol("SetFontSize");
export { SetFontSize }

export class SetFontSizeCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetFontSize, text: "Font Size", ...options });

        this.const("fontSize", options.value);
    }

    execute(ctx) {
        this.object.fontSize = this.fontSize;
    }
}



const SetPadding = Symbol("SetPadding");
export { SetPadding }

export class SetPaddingCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetPadding, text: "Padding", ...options });    
        this.const("value", options.value);
    }

    execute(ctx) {
        this.object.padding = this.value;
    }
}


const SetLineHeight = Symbol("SetLineHeight");
export { SetLineHeight }

export class SetLineHeightCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetLineHeight, text: "Line Height", ...options });    
        this.const("value", options.value);
    }

    execute(ctx) {
        this.object.lineHeight = this.value;
    }
}