import { ObjectCommand } from "./command.js";

const MaskOff = Symbol("MaskOff");
export { MaskOff }

export class MaskOffCommand extends ObjectCommand {
    constructor(options) {
        super({ id: MaskOff, icon: "bi-person-square", text: "Square", ...options });
    }
    execute(ctx) {
        this.object.mask = null;
    }
} 
