import * as Y from "yjs";
import { HocuspocusProvider } from "@hocuspocus/provider";
import { between, START, END } from 'timu-slide/buffer.js';
import uuid from "timu-uuid";
import { StrokeCollisionMargin, StrokeCollisionWidth } from "timu-slide/constants.js";
import { Transitions } from "./transitions/transitions.js";

function def(value, defaultValue) {
    if(value === undefined) {
        return defaultValue;
    }
    return value;
}

export const SketchCursorAction = ()=>null;

class PointerLocation {
    constructor({x, y, user}) {
        this._x = x;
        this._y = y;
        this._user = user;
    }

    get user() {
        return this._user;
    }

    get x() {
        return this._x;
    }

    get y() {
        return this._y;
    }
}

export const LayoutNone = "none";
export const LayoutFlow = "flow";
export const LayoutRows = "rows";
export const LayoutCols = "cols";
export const LayoutGrid = "grid";
export const LayoutTable = "table";


export class Views {
    constructor(doc) {
        this._doc = doc;

        this._browser = true;

        this._watchers = [];
    }

    get browser() {
        return this._browser;
    }

    set browser(value) {
        this._browser = value;
        this.notify({ browser: value });
    }

    watch(fn) {
        const w = () => {
            fn(this);
        }

        this._watchers.push(w);
        return ()=> {
            this._watchers.splice(this._watchers.indexOf(w), 1);
        }
    }

    notify(e) {
        const event = [{ changes: { keys: new Set(Object.keys(e)) }}];
        if(!this.notifyPromise) {
            this.notifyPromise = Promise.resolve().then(()=> {
                this.notifyPromise = null;
                this._watchers.forEach(w => w(event));
            });
        }   
    }
}

export function sync(yDoc, fn) {
    const deltaReplacer = (key, value) => {
        if(value instanceof Y.Map) {
            const map = {

            };
            for(let [k, v] of value.entries()) {
                map[k] = deltaReplacer(k, v);
            }
            return map;
        } else if(value instanceof Y.Text) {
            return { deltas: value.toDelta() };
        } else {
            return value;
        }
    };

    const toJSON = (value) => {
        if(value === undefined || value === null) {
            return value;
        } else if(value.getContent) {
            return JSON.parse(JSON.stringify(value.getContent(), deltaReplacer));
        } else if(value instanceof Y.AbstractType) {
            const replaced = JSON.stringify(deltaReplacer("", value));
            if(replaced === undefined) {
                return replaced;
            }
            return JSON.parse(replaced);
        } else {
            return JSON.parse(JSON.stringify(value, deltaReplacer));
        }
    }
    
    const observer = (root, events) => {
        
        for(let event of events) {
            const {target, changes, path } = event;
        
    
            const msg = {
                path: root.concat(event.path),
                changes: []
            };
            for(let [key,change] of event.changes.keys.entries()) {
                let c = {
                    action: change.action,
                    key: key
                };

                if(change.action == 'add') {
                    c.value = toJSON(target.get(key));
                } else if(change.action == 'update') {
                    const value = target.get(key);
                    if(value === undefined) {
                        c.action == "remove";
                    } else {
                        c.value = toJSON(value);
                    }
                }

                msg.changes.push(c);
            }


            if(event.changes.delta.length > 0) {
                let change = {};
                change.action = "update-delta";
                change.delta = toJSON(event.changes.delta);             
                change.value = toJSON(target);
                change.isLocal = window.__applyingLocalChange;
                msg.changes.push(change);
            } else {

                for(let added of event.changes.added) {
                    let change = {};
                    change.action = "array-insert";
                    // TODO: contentAny vs contentType handling

                    change.values = toJSON(added.content);
                    msg.changes.push(change);
                }

                for(let deleted of event.changes.deleted) {
                    console.warn("Array deleted not handled");
                    /*
                    const yObj = objPath[0];
                    let change = {};
                    change.action = "array-delete";
                    change.path = path;
                    change.value = JSON.parse(JSON.stringify(deleted.value));*/
                }
            }

            // || event.changes.deleted.size
            fn(msg);
        }

        // Checkpoint message
        /*fn({
            full: {
                box: toJSON(yBox),
                object: toJSON(yObj),
            },
            changes: []
        });*/
    
    }

    
    const deferred = [];

    const root = yDoc.share;
    yDoc.on("update", (data) => {
        const doc = yDoc;
    });
    yDoc.on('beforeObserverCalls', event => {
        console.log("Synchronizing")
        for(var key of event.changed.keys()) {
            const e = new Y.YEvent(key, event);
            var root = key;
            var path = [];
            while(root.parent) {
                path.unshift(root._item.parentSub);
                root = root.parent;
            }
            for(var entry of yDoc.share.entries()) {
                if(entry[1] == root) {
                    const fullPath = [entry[0]].concat(path);
                    console.log("Change at ", fullPath);
                    observer(fullPath, [ e ]);
                    break;
                }
            }
        }
    });
    
    for(const key of root.keys()) {
        const value = root.get(key);
        const json =  (value.constructor == Y.AbstractType && value._start?.content) ? toJSON(value._start.content) : toJSON(value);   
        fn({
            path: [],
            changes: [
                {
                    action: "update",
                    key: key,
                    value: json,
                }
            ],
        });
    }
    
    return () => deferred.forEach(d => d());

    /*


    const obs1 = observer("box");
    const obs2 = observer("object");


    fn({
        path: [],
        changes: [
            {
                action: "update",
                key: "box",
                value: {}
            }
        ],
    });
    fn({
        path: [],
        changes: [
            {
                action: "update",
                key: "object",
                value: {}
            }
        ],
    });
    

    fn({
        path: ["box"],
        changes: Array.from(yBox.entries()).map(([key,value]) => {
            return {
                action: "update",
                key: key,
                value: toJSON(value)
            }
        }),
    })
    fn({
        path: ["object"],
        changes: Array.from(yObj.entries()).map(([key,value]) => {
            return {
                action: "update",
                key: key,
                value: toJSON(value)
            }
        }),
    })


    const yBoxSub = yBox.observeDeep(obs1);

    const yObjSub = yObj.observeDeep(obs2);

    return ()=> {
        yBox.unobserveDeep(yBoxSub);
        yObj.unobserveDeep(yObjSub);
    }*/
}


export class BaseDocument {
    constructor(yDoc) {
        this._y = yDoc;
    }

    init() {

    }

    get awareness() {
        return this.provider ? this.provider.awareness : null;

    }

    me() {
        return {
            ...this.awareness.getLocalState().identity,
            clientID: this.awareness.clientID
        };
    }

    connectWebsocket(name) {  
        if(this._y) {
            throw new Error("Already connected");
        }
        this.meetingID = name;
        this.provider = new HocuspocusProvider({
            url: ((window.location.protocol == "http:" ? 'ws://' : 'wss://')+window.location.host)+name, 
            name: name,
        });
            
        this.provider.on('status', event => {
            console.log(event.status) // logs "connected" or "disconnected"
        });

        this.provider.on('synced', () => {
            this._watchForSynced();
        });

        this._y = this.provider.document;    
        this.init();
        this.onConnected();
    }

    _watchForSynced() {                
        /*
        try {
            this._objects.entries();
            this._y.getMap("root").get("objects").entries();
            this._root.getSortedObjects();            
        } catch(err) {
            // scenes are not ready yet, must not be synced
            console.error("Document not ready yet", err);
            setTimeout(() => this._watchForSynced(), 100);
            return;
        }
        */
        // Only call synced once
        if(!this.synced) {
            this.synced = true;
            this.onSynced();
        }
         
    }


    onSynced() {
        console.log("Document synced");
        
        /*if(!this._root._y.get("objects")) {
            console.error("Empty / Uninitialized Document Detected");
            //this._root.init(); 
        }*/

        if(this.onReady) {
            this.onReady();
        }
    }

    onConnected() {
        this.watchAwarness();
    }

    watchAwarness() {
    }

    sync(fn) {
        this.isSynchronizing = true;
        return sync(this._y, fn);
    }
}

export class ChatDocument extends BaseDocument {
    constructor(yDoc) {
        super(yDoc);
    }

    init() {
        super.init();
        this._messages = this._y.getArray("messages");
        this._members = this._y.getArray("members");
        this._settings = this._y.getMap("settings");
        this._recentBoards = this._y.getMap("recentBoards");
        this._recentRecordings = this._y.getArray("recentRecordings");
    }

    get members() {
        return this._members;
    }

    get name() {
        return this._settings.get("name");
    }

    set name(value) {
        return this._settings.set("name", value);
    }

    getRecentBoardEntries() {     
        let objects; 
        if(this._recentBoards) {
            objects = Array.from(this._recentBoards.entries());
        } else {
            objects = [];
        }

        const unsorted = objects;
        unsorted.sort((a,b) => a[1].get("pos") < b[1].get("pos") ? -1 : 1); // TODO: this is slow if we have a lot of objects        
        return objects;
    }
}

export class SettingsDocument extends BaseDocument {
    constructor(yDoc) {
        super(yDoc);
    }
 
    init() {
        super.init();
        this._contacts = this._y.getMap("contacts");
        this._activeChats = this._y.getMap("activeChats");
        this._recentChats = this._y.getMap("recentChats");
        this._recentContacts = this._y.getMap("recentContacts");
        this._recentBoards = this._y.getMap("recentBoards");
        this._recentRooms = this._y.getMap("recentRooms");
        this._pinnedContacts = this._y.getMap("pinnedContacts");
        this._pinnedBoards = this._y.getMap("pinnedBoards");
        
        this._notifications = this._y.getArray("notifications");
        this._emailRequests = this._y.getMap("emailRequests");
        this._boardRequests = this._y.getMap("boardRequests");

        super.init();
    }

    getRecentRooms() {     
        let objects; 
        if(this._recentRooms) {
            objects = Array.from(this._recentRooms.entries());
        } else {
            objects = [];
        }

        const unsorted = objects;
        unsorted.sort((a,b) => a[1].get("pos") < b[1].get("pos") ? -1 : 1); // TODO: this is slow if we have a lot of objects        
        return objects;
    }
}

export class Document extends BaseDocument {
    constructor(yDoc) {
        super(yDoc);
        // TODO: this is a problem

    
    }

    init() {

        super.init();

        this._slide = 0;
        this._watchers = [];
        this._undoManager = new Y.UndoManager([ this._y.getMap("objects"), this._y.getMap("feed"), this._y.getMap("slots"), this._y.getMap("shelf") ]);
        this._fullScreenSlide = null;
        this._currentScene = null;
        this._focusedSlide = null;

        this._selections = new Map();
        this._selectedObjects = new Map();   

        this._views = new Views(this);


        this._root = new Layer(this, this._y, this._y.getMap("document"));

        this._y.getMap("settings"); // init settings
    
        this._shelf = this._y.getMap("shelf");
        this._slots = this._y.getMap("slots");
    }


    get shelf() {
        return this._shelf;
    }

    get slots() {
        return this._slots;
    }

    addSlot({ x, y, width, height, fit, placeholder }) {
        const newSlot = new Y.Map();
        const id =  uuid();
        newSlot.set("id",id);
        newSlot.set("x", x);
        newSlot.set("y", y);        
        newSlot.set("width", width);
        newSlot.set("height", height);
        newSlot.set("placeholder", placeholder);
        if(fit) {
            newSlot.set("fit", fit);    
        }
        this.slots.set(id, newSlot);
        return newSlot;
    }

    getSortedShelf() {
        const objects = Array.from(this._shelf.entries());
        const unsorted = objects;
        unsorted.sort((a,b) => a[1].get("pos") < b[1].get("pos") ? -1 : 1); // TODO: this is slow if we have a lot of objects
        return unsorted.map(x => x[1]);
    }

    addToShelf({ id, boardID, name, type, presenter, duration, layerID, layerInstanceID, layerType, cameraType, videoID, timelineID, slot, speakerIndex, keepExisting = false, recordingKind }) {


        const shelf = this.getSortedShelf();
        
        if(!keepExisting) {
            const existing = shelf.filter((x) => x.get("slot") == slot && x.get("id") != id);
            
            existing.forEach((x) => {
                x.set("slot", null);
                this.shelf.delete(x.get("id"));
            });
        }


        const objs = this.getSortedShelf();
        const after = objs[objs.length - 1];
        //const pos = this._shelf.get(id)?.get("pos") ?? between(after?.get("pos") || START, END);
        const item = new Y.Map();
        item.set("id", id);
        item.set("name", name);
        item.set("type", type);
        //item.set("pos", pos);

        if(recordingKind) {
            item.set("recordingKind", recordingKind);
        }

        item.set("slot", slot);
        item.set("presenter", presenter);
        item.set("duration", duration);
        item.set("layerID", layerID || null);
        item.set("layerInstanceID", layerInstanceID || null);
        item.set("layerType", layerType);
        item.set("videoID", videoID || null);
        item.set("boardID", boardID || null);
        item.set("timelineID", timelineID || null);
        item.set("cameraType", cameraType);
        if(speakerIndex !== undefined) {
            item.set("speakerIndex", speakerIndex);
        }
        this._shelf.set(id, item);


    }

    removeFromShelf(id) {
        this._shelf.delete(id);
    }


    watchAwarness() {
        const awareness = this.awareness

        // You can observe when a user updates their awareness information
        awareness.on('change', changes => {
            changes.updated.forEach(u => {
                const state = awareness.getStates().get(u);
                if(state.pointer) {
                    const pointer = state.pointer;
                    this.scenes.forEach(scene => {
                        scene.objects.forEach(s => {
                            if(s.id == pointer.slide) {
                                s.notify({
                                    pointerEvent: {
                                        pointer
                                    }
                                });
                                if(u == this._followingClientID) {
                                    //this.gotoSlide(s, true);
                                }
                            }
                        });
                    });
                }
                if(state.selectedObjects) {
                    const objects = new Set(state.selectedObjects);
                    this._selections.set(u, objects);
                    this._rebuildSelectionCache();
                }
            });

            changes.removed.forEach(u => {
                this._selections.delete(u);
                this._rebuildSelectionCache();
            })

            this.notify({ awareness: changes });
        });
    }

    get settings() {
        return this._y.getMap("settings");
    }
    
    get _objects() {
        return this._y.getMap("objects");
    }
    get view() {
        return this._views;
    }

    get sketch() {
        return this.cursorAction == SketchCursorAction;
    }

    findObject(id) {
        for(let scene of this.scenes) {
            const o = scene.findObject(id);
            if(o) {
                return o;
            }
        }
        return null;
    }

    // TODO: could make this a LOT more efficient with some caching
    getObjectInstance(objectID, instanceID) { 
        const find = (parent) => {
            for(let o of parent.objects) {
                if(o.instanceID == instanceID) {
                    return o;
                }
                const c = find(o);
                if(c) {
                    return c;
                }
            }
        }

        return find(this._root);
    }

    instantiateObject(id) {
        const yObj = this._objects.get(id);
        return ObjectFactory.fromY(this, new Y.Map(), yObj);        
    }

    getObject(parent, id) {
        const yObj = this._objects.get(id);
        if(parent == null) {
            if(yBox && yObj) {
                return ObjectFactory.fromY(parent, new Y.Map(), yObj);
            }    
        }
        const yBox = parent._objects.get(id);        
        // TODO: this will be super slow, cache it
        if(yBox && yObj) {
            return ObjectFactory.fromY(parent, yBox, yObj);
        }    
        return null;
    }

    get myClientID() {
        return this.awareness.clientID;
    }

    ensureScene() {
        if(!this.currentScene) {
            if(this.scenes.length > 0) {
                this.currentScene = this.scenes[0];
            } else {
                this.currentScene = this.addScene();
            }
        }
    }

    watchScenes(fn) {
        const scenes = this._root._y.get("objects");
        scenes.observe(fn);
        return ()=> {
            if(scenes) {
                scenes.unobserve(fn);
            }
        };
    }

    get currentScene() {
        return this._currentScene;
    }

    set currentScene(value) {
        this._currentScene = value;
        this.notify({ currentScene: value });

        if(value) {
            this.scale = value.defaultZoom || "auto";
        }
    }

    addScreen({ instanceID, createdBy, name, createdAt } = {}) {
        return this._root.addObject("screen", { instanceID, createdBy, name, createdAt });
    }

    addText(text, { instanceID, createdBy, name, createdAt } = {}) {
        return this._root.addText("", { instanceID, createdBy, name, createdAt });
    }

    addIFrame({ instanceID, createdBy, name, createdAt } = {}) {
        return this._root.addIFrame({ instanceID, createdBy, name, createdAt });
    }

    addScene({ id, instanceID, createdBy, name, createdAt, mode } = {}) {
        const scene = this._root.addObject("scene", { staticID: id, instanceID, createdBy, name, createdAt });
        if(mode) {
            scene.mode = mode;
        }
        return scene;
    }

    removeScene(scene) {
    this._root.remove(scene);
        if(this.currentScene == scene) {
            this.scene = null;
        }
    }

    get focusedSlide() {
        return this._focusedSlide;
    }

    set focusedSlide(value) {
        this._focusedSlide = value;
        this.notify({ focusedSlide: value });
    }


    get _codePages() {
        return this._y.getMap("code");
    }


    get code() {
        const codePage =  this._codePages.get("default");
        if(!codePage) {
            return null;
        }
        return ObjectFactory.fromY(this, codePage.get("yBox"), codePage.get("yObj"));
    }

    get fullScreenSlide() { 
        return this._fullScreenSlide;
    }

    set fullScreenSlide(value) {
        this._fullScreenSlide = value;
        this.notify({ fullScreenSlide: value });
    }



    get cursor() {
        return this._cursor || null;
    }

    set cursor(value) {
        this._cursor = value;
        this.notify({ cursor: value });
    }

    get cursorAction() {
        return this._cursorAction || null;
    }

    set cursorAction(value) {
        this._cursorAction = value;
        this.notify({ cursorAction: value });
    }

    get undoManager() {
        return this._undoManager;
    }


    follow(clientID) {
        if(clientID == this.awareness.clientID) {
            this._followingClientID = null;
        } else {
            this._followingClientID = clientID;
            this._updateSlideToMatchFollowing();
        }
        this.notify({ follow: clientID });
    }

    get followingClientID() {
        return this._followingClientID;
    }

    _updateSlideToMatchFollowing() {
        const awareness = this.awareness;

        if(this._followingClientID) {

            const state = awareness.getStates().get(this._followingClientID);

            if(state) {

                const pointer = state.pointer;
                throw new Error("TODO");
            }
        }        
    }

    getSelectedObjects() {

        const selectedObjects = [];
        if(this.focusedSlide) {
            this.focusedSlide.getSortedObjects().forEach(o => {
                if(o.selected) {
                    selectedObjects.push(o)
                }
            });
        } else {
            this.scenes.forEach(s => {
                if(s.selected) {
                    selectedObjects.push(s);
                }
                s.getSelectedObjects().forEach(o => {
                    selectedObjects.push(o);
                });
            });
        }

        return selectedObjects;
    }


    

    _rebuildSelectionCache() {
        this._selectedObjects = new Map();
        for(let [u, ids] of this._selections) {
            for(let id of ids) {
                let all = this._selectedObjects.get(id);
                if(!all) {
                    this._selectedObjects.set(id, all = new Set());
                }
                all.add(u);
            }
        }
    }


    getSlide(id) {
        if(this.focusedSlide?.id == id) {
            return this.focusedSlide;
        }
        for(let scene of this.scenes) {
            const slide = scene.getSlide(id);
            if(slide) {
                return slide;
            }
        }
        return null;
    }

    getMyLocation() {
        if(this.awareness) {
            const state = this.awareness.getStates().get(this.awareness.clientID);
            if(state) {
                const pointer = state.pointer;
                if(pointer) {
                    const scene = this.scenes.find(x => x.id == pointer.scene);
                    return { scene, x: pointer.x, y: pointer.y, user: this.awareness.clientID };
                }
            }
        }
        return null;
    }

    get myPointerLocation() {
        const awareness = this.awareness

        const states = awareness.getStates();
        const state = states.get(this.myClientID);
        if(state) {
            const pointer = state.pointer;
            return new PointerLocation({ 
                x: pointer.x, 
                y: pointer.y, 
                user: this.myClientID
            });
        
        }
        
        return null
    }

    getPointerLocations(scene) {
        const awareness = this.awareness

        let locations = [];
        const states = awareness.getStates();

        for(let k of states.keys()) {
            const state = states.get(k);
            const pointer = state.pointer;
            if(pointer && pointer.scene == scene.id) {
                locations.push(new PointerLocation({ 
                    x: pointer.x, 
                    y: pointer.y, 
                    user: k 
                }));
            }
        }
        return locations;
    }

    setIdentity(identity) {
        const awareness = this.awareness;
        awareness.setLocalStateField('identity', identity);
    }

    setParticipantID(id) {
        const awareness = this.awareness;
        awareness.setLocalStateField('participant', id);
    }


    getConnectedIdentities() {
        const awareness = this.awareness

        const users = [];
        for(let [k,v] of awareness.getStates()) {
            if(v.identity) {
                users.push({...v.identity, ...{ clientID: k, participantID: v.participant }});
            }
        }
        return users;
    }

    setPointerLocation({x, y, scene, slide }) {
        const awareness = this.awareness;

        awareness.setLocalStateField('pointer', {
            scene, x, y, slide
        });
    }

    // document || slide
    setStreamingMode(mode) {
        const awareness = this.awareness;

        awareness.setLocalStateField('streamingMode', {
            mode
        });

        this.notify({ localStreamingMode: mode });
    }

    get streamingMode() {
        const awareness = this.awareness;

        const state = awareness.getStates().get(awareness.clientID);
        const streamingMode = state.streamingMode || {};
        return streamingMode.mode || "document";
    }

    watch(fn, properties = []) {
        const w = (e,t) => {
            if(!e.some || (properties.length == 0 || properties.some(p => e.some(e => e.changes && e.changes.keys.has(p))))) { 
                fn(this);
            }
        }

        this._watchers.push(w);
        this._y.on("update", w);
        return ()=> {
            this._y.off("update", w);
            this._watchers.splice(this._watchers.indexOf(w), 1);
        }
    }

    notify(e) {
        const event = [{ changes: { keys: new Set(Object.keys(e)) }}];
        if(!this.notifyPromise) {
            this.notifyPromise = Promise.resolve().then(()=> {
                this.notifyPromise = null;
                this._watchers.forEach(w => w(event));
            });
        }   
    }

    get scale() {
        return def(this._scale, "auto");
    }

    // presentation | collaboration
    set scale(value) {
        this._scale = value;
        this.notify({ scale: value });
    }


    get presenterSlide() {
        return this._y.getMap("view").get("presenterSlide") || 0;
    }

    // presentation | collaboration
    set presenterSlide(value) {
        this._y.transact(()=> {
            this._y.getMap("view").set("presenterSlide", value);
        });
    }


    get mode() {
        return this._y.getMap("view").get("current") || "collaboration";
    }

    // presentation | collaboration
    set mode(value) {
        this._y.transact(()=> {
            this._y.getMap("view").set("current", value);
            this._y.getMap("view").set("presenterSlide", 0);
            
        });
    }

    get scenes() {
        return this._root.getSortedObjects();
    }

    
    get cursors() {
        return this._cursors;
    }

    set cursors(value) {
        this._cursors = value;
        this.notify({ cursors: value })

        const selectedIDs = new Set();
        this._cursors.forEach(cursor => {
            const from = Y.createAbsolutePositionFromRelativePosition(cursor.from, this._y);
            if(from) {
            let object = from.type;
            if(!object.get) {
                object = object.parent;
            }
            if(object.get) {
                const id = object.get("id");
                if(id) {
                    selectedIDs.add(id);
                }
            }
        }
        });
       

        if(this.awareness) {
            this.awareness.setLocalStateField('selectedObjects', Array.from(selectedIDs));
        }
    }
}

const EmptySet = new Set();

export class Sketch {
    constructor(doc, yObj, yBox){
        this._doc = doc;
        this._yObj = yObj;
    }

    addPoint(x, y, pressure) {
        let last = this._yObj.get(this._yObj.length - 1);
        if(!last) {
            last = new Y.Array();
            this._yObj.push([last]);
        }
        last.push([{ x, y, pressure}]);
    }

    end() {
        this._yObj.push([new Y.Array()]);
    }
}

function ensureCodePage(parent, yObj, key) {
    if(!yObj.get(key)) {
        const yBox = new Y.Map();
        const yObj = new Y.Map();

        const frame = ObjectFactory.fromY(parent, yBox, yObj);
        
        const yCodePage = new Y.Map();
        yCodePage.set("yObj", yObj);
        yCodePage.set("yBox", yBox);

        yObj.set(key, yCodePage);
        
        frame.init();
        frame.width = 1280;
        frame.height = 720;
        frame.kind = "code";
    }
}


export class Transform {
    constructor({ translateZ = 0, translateX = 0, translateY = 0, scaleX = 1.0, scaleY = 1.0, rotate = 0.0 } = {}) {
        this.translateZ = translateZ;
        this.translateX = translateX;
        this.translateY = translateY;
        this.scaleX = scaleX;
        this.scaleY = scaleY;
        this.rotate = rotate;
    }

    reset() {
        this.translateZ = 0;
        this.translateX = 0;
        this.translateY = 0;
        this.scaleX = 1.0;
        this.scaleY = 1.0;
        this.rotate = 0.0;
    }
}

export function clamp(from, to, value) {
    return Math.min(to, Math.max(from, value ));
}

export class Animation {
    constructor({ property, steps, start = 0.0, duration = 1.0, hold = true }) {
        this.property = property;
        this.steps = steps;
        this.start = start;
        this.duration = duration;
        this.hold = hold;
    }

    fn(value, x) {
        return value + x;
    }

    apply(renderContext, object, source) {

        let time = renderContext.time;
       
        if(this.steps.length < 1) {
            throw new Error("Cannot apply an empty animation");
        }
       
        let start = this.start;
        const original = object[this.property];
        let value = original;
      
        for(let i = 0; i < this.steps.length; i++) {
            const step = this.steps[i];
            const sd = this.duration * step.duration;
            if(time >= start && (time <= start + sd || (this.hold && i == this.steps.length - 1))) {
                const t = clamp(0, 1.0, (time - start)/sd);
                const d = renderContext.calcExpression(step.to) - renderContext.calcExpression(step.from);
                const v = renderContext.calcExpression(step.from) + d * t;
                value = this.fn(original, v);
            } else {
                break;
            }
            start += sd;
        }
        object[this.property] = value;

        if(!this.hold && time >= this.start + this.duration) {
            const i = source.animations.indexOf(this);
            if(i != -1) {
                source.animations.splice(i, 1);
            }
        }
    }
}

export class Box { 

    constructor(parent, yBox) {
        if(!yBox) {
            throw new Error("yBox is required");
        }
        this._parent = parent;
        this._ybox = yBox;
        this._watchers = [];
        this._transform = new Transform();
        this._animations = [];
    }

    init() {
    }

    get icon() {
        return "layer-slide";
    }

    get selectedByClients() {
        return this.doc._selectedObjects.get(this.instanceID) || EmptySet;
    }

    isAncestorOf(object) {
        for(let o = object.parent; !!o; o = o.parent) {
            if(o == this) {
                return true;
            }
            
        }
        return false;
    }

    fromDocPoint({ x, y }) {
        x -= this.x;
        y -= this.y;

        if(this.parent?.fromDocPoint) {
            return this.parent.fromDocPoint({ x, y });
        } else {
            return { x, y };
        }
    }


    toDocPoint({ x, y } = { x: 0, y: 0 }) {
        x += this.x;
        y += this.y;

        if(this.parent?.toDocPoint) {
            return this.parent.toDocPoint({ x, y });
        } else {
            return { x, y };
        }
    }


    get animations() {
        return this._animations;
    }

    applyTransform() {
        this.x += this.transform.translateX;
        this.y += this.transform.translateY;
        this.width *= this.transform.scaleX;
        this.height *= this.transform.scaleY;
        this.transform.reset();
    }

    get transform() {
        return this._transform;
    }

    get typeName() {
        return this.constructor.name.toLowerCase();
    }

    snapValue(value) {
        return Math.round(value);
    }

    get snap() {
        return def(this._ybox.get("snap"), 1);
    }

    set snap(value) {
        this._ybox.set("snap", value);
    }

    watch(fn, properties = []) {
        const w = (e,t) => {
            if(!e.some || (properties.length == 0 || properties.some(p => e.some(e => e.changes.keys.has(p))))) { 
                fn(this);
            }
        }

        this._watchers.push(w);
        properties.length ? this._ybox.observeDeep(w) : this._ybox.observe(w);
        return ()=> {
            properties.length ? this._ybox.unobserveDeep(w) : this._ybox.unobserve(w);
            this._watchers.splice(this._watchers.indexOf(w), 1);
        }
    }

    notify(e) {
        const event = [{ changes: { keys: new Set(Object.keys(e)) }}];
        if(!this.notifyPromise) {
            this.notifyPromise = Promise.resolve().then(()=> {
                this.notifyPromise = null;
                this._watchers.forEach(w => w(event));
            });
        }   
    }

    parentOfType(T) {
        let p = this;
        for(p = p.parent; p && !(p instanceof T); p = p.parent) {

        }
        return p;
    }

    get parent() {
        return this._parent;
    }

    get doc() {
        return this.parentOfType(Document);
    }

    get instanceID() {
        return this._ybox.get("id");
    }

    get marginLeft() {
        return def(this._ybox.get("marginLeft"), 0);
    }

    set marginLeft(value) {
        this._ybox.set("marginLeft", value);
    }

    get marginRight() {
        return def(this._ybox.get("marginRight"), 0);
    }

    set marginRight(value) {
        this._ybox.set("marginRight", value);
    }

    get marginTop() {
        return def(this._ybox.get("marginTop"), 0);
    }

    set marginTop(value) {
        this._ybox.set("marginTop", value);
    }

    get marginBottom() {
        return def(this._ybox.get("marginBottom"), 0);
    }

    set marginBottom(value) {
        this._ybox.set("marginBottom", value);
    }

    get autoLayout() {
        return this.position == "default";
    }

    set autoLayout(value) {
        this.position = value ? "default" : "absolute";
    }

    get fixed() {
        return this.position == "fixed";
    }

    set fixed(value) {
        this.position = value ? "fixed" : "default";
    }

    get absolute() {
        return this.position == "absolute";
    }

    set absolute(value) {
        this.position = value ? "absolute" : "default";
    }

    get position() {
        return def(this._ybox.get("position"), "absolute");
    }

    set position(value) {
        if(this.position != value) {
            this._ybox.set("position", value);
            if(this.parent?.performLayout) {
                this.parent.performLayout();
            }
        }
    }


    constrain(value) {
        this.constrainLeft = this.constrainRight = this.constrainTop = this.constrainBottom = value;
    }

    get constrainLeft() {
        return def(this._ybox.get("constrainLeft"), false);
    }
    
    set constrainLeft(value) {
        this._ybox.set("constrainLeft", value);
    }
    
    get constrainRight() {
        return def(this._ybox.get("constrainRight"), false);
    }
    
    set constrainRight(value) {
        this._ybox.set("constrainRight", value);
    }
    
    get constrainTop() {
        return def(this._ybox.get("constrainTop"), false);
    }
    
    set constrainTop(value) {
        this._ybox.set("constrainTop", value);
    }
    
    get constrainBottom() {
        return def(this._ybox.get("constrainBottom"), false);
    }
    
    set constrainBottom(value) {
        this._ybox.set("constrainBottom", value);
    }

    get x() {
        return def(this._ybox.get("x"), 0);
    }

    set x(value) {
        if(this.x != value) {
            this._ybox.set("x", value);
        }
    }

    get y() {
        return def(this._ybox.get("y"), 0);
    }

    set y(value) {
        if(this.y != value) {
            this._ybox.set("y", value);
        }
    }

    get contentWidth() {
        return def(this._ybox.get("contentWidth"), 0);
    }

    set contentWidth(value) {
        this._ybox.set("contentWidth", value);

        if(this.parent?.performLayout) {
            this.parent.performLayout();
        }
        if(this.performLayout) {
            const strat = this.layoutStrategy;
            if(strat instanceof VerticalLayoutStrategy || strat instanceof GridLayoutStrategy) {
                this.performLayout();
            }
        }
    }


    get contentHeight() {
        return def(this._ybox.get("contentHeight"), 0);
    }

    set contentHeight(value) {
        this._ybox.set("contentHeight", value);

        if(this.parent?.performLayout) {
            this.parent.performLayout();
        }
        if(this.performLayout) {
            const strat = this.layoutStrategy;
            if(strat instanceof HorizontalLayoutStrategy || strat instanceof GridLayoutStrategy) {
                this.performLayout();
            }
        }
    }



    get minWidth() {
        return def(this._ybox.get("minWidth"), 0);
    }

    set minWidth(value) {
        this._ybox.set("minWidth", value);

        if(this.parent?.performLayout) {
            this.parent.performLayout();
        }
        if(this.performLayout) {
            const strat = this.layoutStrategy;
            if(strat instanceof VerticalLayoutStrategy || strat instanceof GridLayoutStrategy) {
                this.performLayout();
            }
        }
    }


    get minHeight() {
        return def(this._ybox.get("minHeight"), 0);
    }

    set minHeight(value) {
        this._ybox.set("minHeight", value);

        if(this.parent?.performLayout) {
            this.parent.performLayout();
        }
        if(this.performLayout) {
            const strat = this.layoutStrategy;
            if(strat instanceof HorizontalLayoutStrategy || strat instanceof GridLayoutStrategy) {
                this.performLayout();
            }
        }
    }

    get width() {
        return Math.max(def(this._ybox.get("width"), this.contentWidth), this.minWidth);
    }

    set width(value) {
        if(this._ybox.get("width") != value) {
            const diff = value - this.width;
            this._ybox.set("width", value);
            this.onWidthChange(diff);
            if(this.parent?.performLayout) {
                this.parent.performLayout();
            }
            if(this.performLayout) {
                const strat = this.layoutStrategy;
                if(strat instanceof VerticalLayoutStrategy || strat instanceof GridLayoutStrategy) {
                    this.performLayout();
                }
            }
        }
    }

    get height() {
        return Math.max(def(this._ybox.get("height"), this.contentHeight), this.minHeight);
    }

    set height(value) {
        if(this._ybox.get("height") != value) {
            const diff = value - this.height;
            this._ybox.set("height", value);
            this.onHeightChange(diff);
            if(this.parent?.performLayout) {
                this.parent.performLayout();
            }
            if(this.performLayout) {
                const strat = this.layoutStrategy;
                if(strat instanceof HorizontalLayoutStrategy || strat instanceof GridLayoutStrategy) {
                    this.performLayout();
                }
            }
        }
    }

    onWidthChange(diff) {
        
    }

    onHeightChange(diff) {

    }

    get pos() {
        return this._ybox.get("pos") || START;
    }

    set pos(value) {
        this._ybox.set("pos", value);      
    }

    select(add = false) {
        const cursor = new Cursor(this.doc, Y.createRelativePositionFromTypeIndex(this._ybox, 0), Y.createRelativePositionFromTypeIndex(this._ybox));        
        
        if(add) {
            this.doc.cursors = (this.doc.cursors || []).concat(cursor);
        } else {
            this.doc.cursors = [ cursor ];
        }
    }

    deselect() {        
        if(this.doc.cursors) {
            for(let cursor of this.doc.cursors) {
                const from = Y.createAbsolutePositionFromRelativePosition(cursor.from, this.doc._y);
                if(from && from.type == this._ybox) {
                    this.doc.cursors = this.doc.cursors.filter(x => x != cursor);
                    return true;
                }             
            }
        }
        return false;
    }

    get selected() {        
        if(this.doc?.cursors) {
            for(let cursor of this.doc.cursors) {
                const from = Y.createAbsolutePositionFromRelativePosition(cursor.from, this.doc._y);
                if(from && from.type == this._ybox) {
                    return true;
                }             
            }
        }
        return false;
    }
}

const layoutStrategies = new Map();

function registerLayoutStrategy(s) {
    layoutStrategies.set(s.type, s);
}

class LayoutStrategy {
    constructor(type) {
        this._type = type;
    }
    get type() {
        return this._type;
    }
    performLayout(props, objects) {
    }
}

class GridLayoutStrategy extends LayoutStrategy {
    constructor() {
        super(LayoutGrid);
    }

    get resizesChildren() {
        return true;
    }

    performLayout(props, objects) {
        const c = props.columns;
        const r = props.rows;
            
        const h = (props.height - props.paddingTop - props.paddingBottom) / r;
        const w = (props.width  - props.paddingLeft - props.paddingRight) / c;
    
        objects.forEach((o, i) => {
            if(!o.dragging) {
                o.y = props.paddingTop + h * Math.floor(i / c);
                o.height = h;
                o.x = props.paddingLeft + w * (i % c);
                o.width = w;
            }
        });
    }
}

registerLayoutStrategy(new GridLayoutStrategy());


class HorizontalLayoutStrategy extends LayoutStrategy {
    constructor(type) {
        super(type);
    }
}


class VerticalLayoutStrategy extends LayoutStrategy {
    constructor(type) {
        super(type);
    }
}

class ColumnsLayoutStrategy extends HorizontalLayoutStrategy {
    constructor() {
        super(LayoutCols);
    }

    performLayout(props, objects) {
        let x = props.paddingLeft;
        let maxHeight = 0;
        objects.forEach((o, i) => {
            o.y = props.paddingTop;
            o.x = x;
            x += props.spacing + o.width;
            maxHeight = Math.max(maxHeight, o.height);
        });

        return x - props.spacing + props.paddingRight;;
    }
}

registerLayoutStrategy(new ColumnsLayoutStrategy());


class RowsLayoutStrategy extends VerticalLayoutStrategy {
    constructor() {
        super(LayoutRows);
    }

    performLayout(props, objects) {
        let y = props.paddingTop;
        let maxWidth = 0;
        objects.forEach((o, i) => {
            o.x = props.paddingLeft;
            o.y = y;
            y += props.spacing + o.height;
            maxWidth = Math.max(maxWidth, o.width);
        });
        return y - props.spacing + props.paddingBottom;
    }
}

registerLayoutStrategy(new RowsLayoutStrategy());


class FlowLayoutStrategy extends VerticalLayoutStrategy {
    constructor() {
        super(LayoutFlow);
    }

    performLayout(props, objects) {
        let x = props.paddingLeft;
        let y = props.paddingTop;
        let lastRowHeight = 0;
        objects.forEach((o, i) => {
            if(props.width  < x + o.width + props.paddingRight) {  /*+ this.paddingRight*/
                if(x != props.paddingLeft) {
                    y += props.spacing + lastRowHeight;
                    lastRowHeight = 0;
                }
                x = props.paddingLeft;
            }
            o.x = x;
            o.y = y;
            x += props.spacing + o.width;
            lastRowHeight = Math.max(lastRowHeight, o.height);
        });

        return y + lastRowHeight;

    }
}

registerLayoutStrategy(new FlowLayoutStrategy());

export class Delegate {
    constructor() {
        this._watchers = [];
    }

    watch(fn) {
        const w = () => {
            fn(this);
        }

        this._watchers.push(w);
        return ()=> {
            this._watchers.splice(this._watchers.indexOf(w), 1);
        }
    }

    trigger(e) {
        for(let w in this._watchers) {
            w(e);
        }
    }
}

export class Event {
    constructor() {

    }
}

export class PointerEvent {
    constructor({ x, y, buttons }) {
        this._x = x;
        this._y = y;
        this._buttons = buttons;
    }
    
    get buttons() {
        return this._buttons;
    }

    get x() {
        return this._x;
    }
    get y() {
        return this._y;
    }
}


export class Layer extends Box {
    constructor(parent, yObj, yBox) {
        super(parent, yBox);

        this.isRoot = yObj instanceof Y.Doc;
        if(this.isRoot) {
            yObj.doc = yObj;
        }
        this._y = yObj;
      

        this._click = new Delegate();
    }

    sendToBack() {
        const start = this.parent.getSortedObjects()[0].pos;
        this.pos = between(START, start);
    }

    bringToFront() {
        const objs = this.parent.getSortedObjects();
        const end = objs[objs.length - 1].pos;
        this.pos = between(end, END);
    }


    get bounds() {
        var w = this.width;
        var left = this.x;
        if (w < 0) {
            left += w;
            w = -w;
        }

        var h = this.height;
        var top = this.y;

        if (h < 0) {
            top += h;
            h = -h;
        }

        return { x: left, y: top, width: w, height: h };
    }

    get comments() {
        if(!this._y.get("comments")) {
            this._y.set("comments", new Y.Array());
        }
        return this._y.get("comments").map(c => CommentFactory.fromY(this, c));
    }
    
    addComment({text, reference, transcribed }) {
        const o = CommentFactory.fromY(this, new Y.Map());
        o.id = uuid();
        o.createdBy = this.doc.me();
        o.text = text;
        o.transcribed = transcribed;
        if(reference) {
            o.reference = reference;
        }
        o.createdAt = new Date().toISOString();
        this._y.get("comments").push([o._y]);
        return o;
    }



    changePosition(index) {
        const objects = this.parent.getSortedObjects();
        this.pos = between( objects[index - 1]?.pos || START, objects[index].pos);
    }


    delete() {
        console.log("deleting object", this.id);
        this.parent.remove(this);
    }


    get click() {
        return this._click;
    }

    toJSON() {
        return { object: this._y, box: this._ybox };
    }

    findObject(id) {
        if(id == this.id) {
            return this;
        }
        for(let child of this.objects) {
            const o = child.findObject(id);
            if(o) {
                return o;
            }
        }
        return null;
    }

    onWidthChange(diff) {
        super.onWidthChange(diff);

        this.objects.forEach(o => {
            if(o.constrainRight) {
                if(o.constrainLeft) {
                    o.width += diff;
                } else {
                    o.x += diff;
                }
            }
        });
    }

    onHeightChange(diff) {
        super.onHeightChange(diff);

        this.objects.forEach(o => {
            if(o.constrainBottom) {
                if(o.constrainTop) {
                    o.height += diff;
                } else {
                    o.y += diff;
                }
            }
        });
    }

    get objects() {
        const objs = this._objects;
        return Array.from(objs ? objs.keys() : []).map(id => {
            return this.doc.getObject(this, id);
        });
    }

    getSelectedObjects() {
        const selectedObjects = [];
        this.objects.forEach(object => {
            if(object.selected) {
                selectedObjects.push(object);
            }
            if(object.getSelectedObjects) {
                object.getSelectedObjects().forEach(o => {
                    selectedObjects.push(o)
                });
            }
        });
        return selectedObjects;
    }

    getObjectAtPoint({x, y}, filter = ()=>true, boundsCheck) {
        // TODO: REVERSE order to get top point
        const objs = this.getSortedObjects();
        for(let i = objs.length - 1; i >= 0; i--) {
            const o = objs[i];
            const relativePoint = { x: x - o.x, y: y - o.y };
            const inBounds = !boundsCheck || boundsCheck(o, relativePoint);
            if((x >= o.x && y >= o.y && x <= o.x + o.width && y <= o.y + o.height) || boundsCheck && inBounds) {
                const obj = o.getObjectAtPoint && o.getObjectAtPoint(relativePoint, filter, boundsCheck) || o;
                if(filter(obj) && (o != obj || inBounds)) {
                    return obj;
                }
            }
        }
        return null;
    }

    get id() {
        return this._y.get("id");
    }

    get opacity() {
        return def(this._y.get("opacity"), 1.0);
    }

    set opacity(value) {
        if(Number.isNaN(value)) return;
        this._y.set("opacity", Math.max(Math.min(value, 1.0), 0));
    }

    get spacing() {
        return def(this._y.get("spacing"), 25);
    }

    get createdBy() {
        return def(this._y.get("createdBy"), null);
    }


    get createdAt() {
        return def(this._y.get("createdAt"), null);
    }

    set spacing(value) {
        this._y.set("spacing", value);
        this.performLayout();
    }

    get mask() {
        return def(this._y.get("mask"), true);
    }

    set mask(value) {
        this._y.set("mask", value);
    }

    get paddingLeft() {
        return def(this._y.get("paddingLeft"), 0);
    }

    set paddingLeft(value) {
        this._y.set("paddingLeft", value);
        this.performLayout();
    }

    get paddingRight() {
        return def(this._y.get("paddingRight"), 0);
    }

    set paddingRight(value) {
        this._y.set("paddingRight", value);
        this.performLayout();
    }

    get paddingTop() {
        return def(this._y.get("paddingTop"), 0);
    }

    set paddingTop(value) {
        this._y.set("paddingTop", value);
        this.performLayout();
    }

    get paddingBottom() {
        return def(this._y.get("paddingBottom"), 0);
    }

    set paddingBottom(value) {
        this._y.set("paddingBottom", value);
        this.performLayout();
    }

    get sketches() {
        let sketches = this._y.get("sketches");
        if(!sketches) {
            sketches = new Y.Map();
            this._y.set("sketches", sketches);
        }
        return sketches;
    }


    get sketch() {
        let sketches = this._y.get("sketch");
        if(!sketches) {
            sketches = new Y.Map();
            this._y.set("sketch", sketches);
        }
        return sketches;
    }


    getSketch(participant) {
        let sketch = this.sketches.get(String(participant));
        if(!sketch) {
            sketch = new Y.Array();
            this.sketches.set(String(participant), sketch);
        }
        return new Sketch(this.doc, sketch);
    }

    clearSketches() {
        for(let a of this.sketches.values()) {
            a.delete(0, a.length);
        }
    }

    get _text() {
        return this._y.get("text");
    }

    _removeEmbeds(from, to) {

        const start = Math.min(from, to);
        const end = Math.max(from, to);
  
        const embeds = new Set();
        let pos = 0;
        for(let delta of this._text.toDelta()) {
            if(pos >= start && pos < end) {
                const embedID = delta?.attributes?.embed;
                if(embedID) {
                    const object = this.getObject(embedID);
                    if(object) {
                        embeds.add(object);
                    } else {
                        console.error("Missing object in embed", this.id, embedID);
                    }
                }
            }
            pos += delta.insert.length;
        }

        for(let e of embeds) {
            this.remove(e, false, false);
        };
    
    }

    _onTextUpdated() {
    
    }

    get layout() {
        return def(this._y.get("layout"), LayoutNone);
    }

    get layoutStrategy() {
        return layoutStrategies.get(this.layout);
    }

    set layout(value) {
        if(this.layout != value) {
            this._y.set("layout", value);
            if(value != LayoutNone) {
                this.forEachObject(o => {
                    if(!o.fixed) {
                        o.autoLayout = true;
                    }
                });
            } else {
                this.forEachObject(o => {
                    if(!o.fixed) {
                        o.autoLayout = false;
                    }
                });
            }
            this.performLayout();
        }
    }


    get autoResize() {
        return def(this._y.get("autoResize"), false);
    }

    set autoResize(value) {
        this._y.set("autoResize", value);
    }

    get name() {
        return def(this._y.get("name"), "");
    }

    set name(value) {
        this._y.set("name", value);
    }

    get source() {
        return def(this._y.get("source"), "");
    }

    set source(value) {
        this._y.set("source", value);
    }

    get hidden() {
        return def(this._y.get("hidden"), false);
    }

    set hidden(value) {
        this._y.set("hidden", value);
    }

    get locked() {
        return def(this._y.get("locked"), false);
    }

    set locked(value) {
        this._y.set("locked", value);
    }

    init() {
        super.init();
        this._y.set("objects", new Y.Map());
        this._y.set("comments", new Y.Array());
        this._y.set("reactions", new Y.Map());
    }



    setReaction({ reaction, createdBy }) {        
        this.reactions.set(createdBy,reaction);
    }

    get myReaction() {
        const me = this.doc.awareness.clientID;
        return this.reactions && this.reactions.get(me);
    }

    removeReaction(createdBy) {        
        this.reactions.delete(createdBy);
    }

    get reactions() {
        if(!this._y.get("reactions")) {
            this._y.set("reactions", new Y.Map());
        };
        return this._y.get("reactions");
    }


    watchObjects(fn) {
        this._objects.observe(fn);
        return ()=> {
            if(this._objects) {
                this._objects.unobserve(fn);
            }
        };
    }

    clone(o) {
        const newObject = this.addYObject(o._y.clone(), uuid(), false);
        return newObject;
    }

    move(o) { 
        const yBox = o.parent._objects.get(o.id).clone();
        o.parent.remove(o, false);
        const objs = this.getSortedObjects();
        const top = objs[objs.length - 1];
        yBox.set("id", uuid());
        yBox.set("pos", between(top || START, END))
        this._objects.set(o.id, yBox);
        return ObjectFactory.fromY(this, yBox, o._y);
    }

    addYObject(yObj, staticID, init = true, instanceID = uuid()) {
        const yBox = new Y.Map();
        yBox.set("id", instanceID);

        let obj;
        this._y.doc.transact(()=> {
            const id = staticID || uuid();
            yObj.set("id", id);
            const objs = this.getSortedObjects();
            const lastIndex = objs.findIndex(x => x.alwaysOnTop);
            const before = objs[lastIndex - 1];
            let pos;
            if(lastIndex != -1) {
                pos = between(before?.pos || START, objs[lastIndex].pos);
            } else if(objs.length > 0) {
                pos = between(objs[objs.length - 1].pos || START, END);
            } else {
                pos = between(START, END);
            }
            yBox.set("pos", pos);
            this.doc._objects.set(id, yObj);
            this._objects.set(id, yBox);
            obj = ObjectFactory.fromY(this, yBox, yObj);
            if(init && typeof(obj.init) == "function") {
                obj.init();
            }

            if(!obj.x === undefined) {
                obj.x = 0;
            }
            if(!obj.y === undefined) {
                obj.y = 0;
            }
        });

        this.performLayout();


        return obj;
    }

    performLayout(async = true) {
        // TODO: remove all calls to this
    }

    addObject(type, { staticID, instanceID, createdBy, name, createdAt }) {
        if(!type) {
            throw new Error("Object type is required to insert into slide");
        }
        const o = new Y.Map();
        o.set("type", type);
        o.set("createdBy", createdBy);
        o.set("createdAt", createdAt);
        return this.addYObject(o, staticID, true, instanceID);
    }

    addJSONObject(json, retainID) {

        const obj = this.addObject(json.object.type, { staticID: retainID ? json.object.id : undefined });
        // TODO: Hack
        Object.keys(json.object).forEach(k => {
            try {
                obj[k] = json.object[k];
            } catch(_) {
                // TODO: is there a better way?
            }
        });

        Object.keys(json.box).forEach(k => {
            if(k != "id" && k != "pos") {
                try {
                    obj[k] = json.box[k];
                } catch(_) {
                    // TODO: is there a better way?
                }
            }
        });
        return obj;
    }

    addSticky(text = "Write your thoughts...", { id, instanceID, createdBy, name, createdAt }= {}) {
        let shape;
        this._y.doc.transact(()=> {
            shape = this.addObject("sticky", { staticID: id, instanceID, createdBy, name, createdAt });
            shape.name = "Sticky";
            shape.text = text;
            shape.background = { color: "#ffffee" };
            shape.borderColor = "#ddddcc";
            shape.borderWidth = 1;
            shape.color = "#000000";
            /*shape.boxShadow = {
                color: "#000000",
                x: 20,
                y: 20,
            };*/
            shape.outlineColor = null;
            shape.fontName = "Roboto"
            shape.fontSize = 14;
            shape.fontWeight = 400;
            shape.fontStyle = "normal";
            shape.padding = 6;
            shape.resizeMode = "minHeight";
            shape.verticalAlignment = "top";
            shape.alignment = "left";
        });
        return shape;
    }

    addCode(language = "javascript", { id, instanceID, createdBy, name, createdAt }= {}) {
        let t;
        this._y.doc.transact(()=> {
            t = this.addObject("code", { staticID: id, instanceID, createdBy, name, createdAt });

            t.language = language;
        });

        return t;
    }


    addPrompt({ id, response, prompt, context, instanceID, createdBy, name, createdAt }) {
        let t;
        this._y.doc.transact(()=> {
            t = this.addObject("prompt", {  staticID: id, instanceID, createdBy, name, createdAt });
            t.prompt = prompt;
            t.response = response;
            t.context = context;
        });   
        return t;
    }

    addIFrame({ id, src, alt, instanceID, createdBy, name, createdAt }= {}) {
        let t;
        this._y.doc.transact(()=> {
            t = this.addObject("iframe", {  staticID: id, instanceID, createdBy, name, createdAt });
            t.src = src;
            t.alt = alt;
        });
        return t;
    }

    addScene({ id, instanceID, createdBy, name, createdAt, mode } = {}) {
        const scene = this.addObject("scene", {  staticID: id, instanceID, createdBy, name, createdAt });
        if(mode) {
            scene.mode = mode;
        }
        return scene;
    }


    addText(text = "Text", { id, instanceID, createdBy, name, createdAt }= {}) {
        let t;
        this._y.doc.transact(()=> {
            t = this.addObject("text", {  staticID: id, instanceID, createdBy, name, createdAt });

            //t.outlineColor = "#ffffff";
            //t.outlineWidth = 2;
            t.text = text;
            t.color = "#000000";
            t.fontName = "Roboto"
            t.fontSize = 40;
            t.fontWeight = 400;
            t.fontStyle = "normal";

            //t.resizeOnLoad = true;
        });

        return t;
    }

    addLink({ id, url, image, title, instanceID, createdBy, name, createdAt }= {}) {
        const link = this.addObject("link", {  staticID: id, instanceID, createdBy, name, createdAt });
        link.url = url;
        link.image = image;
        link.title = title;
        return link;
    }

    addWidget({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("widget", {  staticID: id, instanceID, createdBy, name, createdAt });
    }

    addImageCollection({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("imageCollection", {  staticID: id, instanceID, createdBy, name, createdAt });
    }

    addImage({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("image", { staticID: id, instanceID, createdBy, name, createdAt });
    }

    addPdf({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("pdf", {  staticID: id, instanceID, createdBy, name, createdAt });
    }

    addAnnotation({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("annotation", {  staticID: id, instanceID, createdBy, name, createdAt });
    }

    addFrame({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("frame", {  staticID: id, instanceID, createdBy, name, createdAt });
    }

    addChat({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("chat", {  staticID: id, instanceID, createdBy, name, createdAt });
    }

    addGrid({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("grid", {  staticID: id, instanceID, createdBy, name, createdAt });
    }

    addShape(kind = "rectangle", { id, instanceID, createdBy, name, createdAt }= {}) {
        let shape;
        this._y.doc.transact(()=> {
            shape = this.addObject("shape", {  staticID: id, instanceID, createdBy, name, createdAt });
            shape.text = "";
            shape.background = { color: "#ffffff"};
            shape.borderWidth = 1;
            shape.borderColor = "#000000";
            shape.color = "#000000";
            shape.outlineColor = null;
            shape.fontName = "Roboto"
            shape.fontSize = 40;
            shape.fontWeight = 400;
            shape.fontStyle = "normal";
            shape.resizeMode = "none";
            shape.shape = kind;
            shape.verticalAlignment = "center";
            shape.alignment = "center";
        });
        return shape;
    }

    addLine({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("line", { staticID: id, instanceID, createdBy, name, createdAt });
    }

    addLottie({ id, instanceID, createdBy, name, createdAt }= {}) {
        return this.addObject("lottie", { staticID: id, instanceID, createdBy, name, createdAt });
    }

    addVideo({ id, instanceID, createdBy, name, createdAt } = {}) {
        return this.addObject("video", { staticID: id, instanceID, createdBy, name, createdAt });
    }

    addFile({ id, instanceID, createdBy, name, createdAt } = {}) {
        return this.addObject("file", { staticID: id, instanceID, createdBy, name, createdAt });
    }

    addRiveAnimation({ id, instanceID, createdBy, name, createdAt } = {}) {
        return this.addObject("rive", { staticID: id, instanceID, createdBy, name, createdAt });
    }

    addAudio({ id, instanceID, createdBy, name, createdAt } = {}) {
        return this.addObject("audio", { staticID: id, instanceID, createdBy, name, createdAt });
    }
    
    addLocalCamera({ id, instanceID, createdBy, name, createdAt } = {}) {
        const cam = this.addObject("camera", { staticID: id, instanceID, createdBy, name, createdAt });
        cam.participant = "local";
        return cam;
    }

    addRemoteCamera({ id, instanceID, createdBy, name, createdAt } = {}) {
        const cam = this.addObject("camera", { staticID: id, instanceID, createdBy, name, createdAt });
        cam.participant = "remote";
        return cam;
    }

    addCameraGrid({ id, instanceID, createdBy, name, createdAt } = {}) {
        const cam = this.addObject("cameraGrid", {staticID: id,  instanceID, createdBy, name, createdAt });
        return cam;
    }

    addScreen({ id, instanceID, createdBy, name, createdAt } = {}) {
        return this.addObject("screen", { staticID: id, instanceID, createdBy, name, createdAt });
    }


    get _objects() {
        return this.isRoot ? this._y.getMap("feed") : this._y.get("objects");
    }

    remove(object, removeFromDoc = true) {
        console.log("removing object", object.id, " from layer", this.id);
        this._y.doc.transact(()=> {
            this._objects.delete(object.id);
            if(removeFromDoc) {
                this.doc._objects.delete(object.id);
            }
        });
        this.performLayout();
    }

    getObject(id) {
        if(this._objects.get(id)) {
            return this.doc.getObject(this, id); 
        }
        return null;
    }

    getObjectWithName(name) {
        return this.getSortedObjects().find(x => x.name == name) || null;
    }

    getObjectsOfType(type) {
        return this.getSortedObjects().filter(x => x.type == type);
    }


    getSortedObjects() {
     
        let objects; 
        if(this._objects) {
            objects = Array.from(this._objects.entries());
        } else {
            objects = [];
        }

        const unsorted = objects;
        unsorted.sort((a,b) => a[1].get("pos") < b[1].get("pos") ? -1 : 1); // TODO: this is slow if we have a lot of objects
        const doc = this.doc;
        return unsorted.map(x => doc.getObject(this, x[0])).filter(x => !!x);
    }

    forEachObject(cb) {
        this.getSortedObjects().forEach(cb);
    }

    deepForEach(cb) {
        for(let o of this.objects) {
            cb(o);
            if(o.deepForEach) {
                o.deepForEach(cb);
            }
        }
    }
}



export class ObjectFactory {
    static constructors = new Map();
    static objects = new WeakMap();

    static fromJSON(parent, json) {
        const obj = json.object;
        const box = json.box;

        const doc = new Y.Doc();
        
        const yObj = doc._y.getMap("yObj");
        const yBox = doc._y.getMap("yBox");

        Object.keys(obj).forEach(k => {
            try {
                yObj.set(k, obj[k]);
            } catch(_) {
                // TODO: is there a better way?
            }
        });

        Object.keys(box).forEach(k => {
            try {
                yBox.set(k, box[k]);
            } catch(_) {
                // TODO: is there a better way?
            }
        });


        return ObjectFactory.fromY(parent, yBox, yObj);
    }

    static fromY(parent, yBox, yObj) {
        if(!yObj || !yBox) {
            return null;
        }
        const type = yObj.get("type");
        let obj = ObjectFactory.objects.get(yBox);

        if(obj) {
            return obj;
        } else {
            const C = ObjectFactory.constructors.get(type);
            if(!C) {
                throw new Error("Unregistered slide object type: "+type);
            }
            obj = new C(parent, yObj, yBox);
            ObjectFactory.objects.set(yBox, obj);
            return obj;
        }
    }
}

export class Scene extends Layer {
    constructor(parent, yObj, yBox) {
        super(parent, yObj, yBox);
    }


    init() {
        super.init();
        this.layout = LayoutNone;
        this.width = 1920;
        this.height = 1080;
        this.paddingLeft = this.paddingRight = this.paddingTop = this.paddingBottom = 0;
        this.marginLeft = this.marginRight = this.marginTop = this.marginBottom = 0;
    }

    get background() {
        return def(this._y.get("background"), { grid: true });
    }

    set background(value) {
        this._y.set("background", value);
    }

    get mode() {
        return def(this._y.get("mode"), null);
    }

    set mode(value) {
        this._y.set("mode", value);
    }

    setBackgroundColor(color) {
        this.background = { color };
    }

    setBackgroundImage(src) {
        this.background = { image: { src } };
    }

    setBackgroundImageNoun(noun) {
        this.background = { image: { noun } };
    }

    get defaultZoom() {
        return def(this._y.get("defaultZoom"), "auto");
    }

    set defaultZoom(value) {
        this._y.set("defaultZoom", value);
    }

    get name() {
        return def(this._y.get("name"), "");
    }

    set name(value) {
        this._y.set("name", value);
    }
    
    get xAlign() {
        return def(this._y.get("xAlign"), "center");
    }

    set xAlign(value) {
        this._y.set("xAlign", value);
    }

    get yAlign() {
        return def(this._y.get("yAlign"), "center");
    }

    set yAlign(value) {
        this._y.set("yAlign", value);
    }

    get drawViewport() {
        return def(this._y.get("drawViewport"), false);
    }

    set drawViewport(value) {
        this._y.set("drawViewport", value);
    }

    get defaultObjectType() {
        return def(this._y.get("defaultObjectType"), "frame");
    }

    set defaultObjectType(value) {
        this._y.set("defaultObjectType", value);
    }

    get defaultItemWidth() {
        return def(this._y.get("defaultItemWidth"), 400);
    }

    set defaultItemWidth(value) {
        this._y.set("defaultItemWidth", value);
    }

    get defaultItemHeight() {
        return def(this._y.get("defaultItemHeight"), 400);
    }

    set defaultItemHeight(value) {
        this._y.set("defaultItemHeight", value);
    }


    get infinite() {
        return def(this._y.get("infinite"), false);
    }

    set infinite(value) {
        this._y.set("infinite", value);
    }
    
    get _slides() {
        return this._y.get("slides");
    }

    watchObjects(fn) {
        this._objects.observe(fn);
        return ()=> {
            if(this._objects) {
                this._objects.unobserve(fn);
            }
        };
    }

    removeSlide(slide) {
        this.delete(slide);
    }

}

ObjectFactory.constructors.set("scene", Scene);


export class CommentFactory {
    static objects = new WeakMap();

    static fromY(parent, yObj) {
        if(!yObj) {
            return null;
        }
        let obj = CommentFactory.objects.get(yObj);

        if(obj) {
            return obj;
        } else {
            obj = new Comment(parent, yObj);
            CommentFactory.objects.set(yObj, obj);
            return obj;
        }
    }
}


export class ConnectionSlot {
    constructor(parent, id, x = 0, y = 0, r = 20) {
        this.id = id;
        this._parent = parent;
        this._x = x;
        this._y = y;
        this._r = r;
    }

    get color() {
        return "#000000";
    }


    get hoverColor() {
        return "#7752ff";
    }

    get parent() {
        return this._parent;
    }

    get radius() {
        return this._r;
    }

    get x() {
        return this.parent.width * this._x;
    }


    get y() {
        return this.parent.height * this._y;
    }

    get targetObjectID() {
        return this.parent._y.get("connections").get(this.id)?.object;
    }

    get targetSlotID() {
        return this.parent._y.get("connections").get(this.id)?.slot;
    }

    get connectedTo() {
        const { object, slot } = this.parent._y.get("connections").get(this.id) || {};
        const o = this.parent.doc.getObject(this.parent.parent, object);
        if(o) {
            return o.getConnectionSlots().find(x => x.id == slot);
        }
        return null;
    }

    connectTo(slot) {
        this.parent._y.get("connections").set(this.id, { object: slot.parent.id, slot: slot.id });
    }

    connectedFrom() {
        // TODO: use for instead of foreach for earlier break
        let source;
        this.parent.parent.getSortedObjects().forEach(o => {
            o.getConnectionSlots().forEach(slot => {
                if(slot.targetObjectID == this.parent.id && slot.targetSlotID == this.id) {
                    source = slot;
                }
            })
        });
        return source;
    }

    disconnect() {
        this.parent._y.get("connections").delete(this.id);
    }
}

export class Bounds {
    constructor({ x, y, width, height, rotate = 0 }) {
        this.x = x;
        this.y = y;
        this.width = width;
        this.height = height;
        this.rotate = rotate;
    }

    get area() {
        return this.width * this.height;
    }

    intersectingArea(box) {
        return Math.max(0, Math.min(box.x + box.width, this.x + this.width) 
            - Math.max(box.x, this.x)) 
            * Math.max(0, Math.min(box.y + box.height, this.y + this.height) 
            - Math.max(box.y, this.y));
    }  
}

export class SlideObject extends Layer {
    constructor(parent, yObj, yBox) {
        super(parent, yObj, yBox);     
        
        this._dragging = false;
        this._snapping = true;

        this._slots = [ 
            new ConnectionSlot(this, ConnectionSlotTop, .5, 0),
            new ConnectionSlot(this, ConnectionSlotLeft, 0, .5),  
            new ConnectionSlot(this, ConnectionSlotBottom, .5, 1),  
            new ConnectionSlot(this, ConnectionSlotRight, 1, .5)
        ];
    }

    get autoSize() {
        return def(this._y.get("autoSize"), false);
    }

    set autoSize(value) {
        this._y.set("autoSize", value);
    }

    get fixedSize() {
        return def(this._y.get("fixedSize"), false);
    }

    set fixedSize(value) {
        return this._y.set("fixedSize", value);
    }


    get annotation() {
        return def(this._y.get("annotation"), false);
    }

    set annotation(value) {
        return this._y.set("annotation", value);
    }

    get annotationChat() {
        return def(this._y.get("annotationChat"), null);
    }

    set annotationChat(value) {
        return this._y.set("annotationChat", value);
    }


    get boxAlignmentX() {
        return def(this._y.get("boxAlignmentX"), 0);
    }

    set boxAlignmentX(value) {
        this._y.set("boxAlignmentX", value);
    }

    get boxAlignmentY() {
        return def(this._y.get("boxAlignmentY"), 0);
    }

    set boxAlignmentY(value) {
        this._y.set("boxAlignmentY", value);
    }


    positionInLayout() {
        if(this.fixed) {
            return;
        }
        console.log("Position in layout")
        if(this.parent.layout != LayoutNone) {
            const objects = this.parent.getSortedObjects();
            let before = -1;
           
            for(let i = 0; i < objects.length; i++) {
                const o = objects[i];
                if(o != this) {
                    if((this.x <= o.x || (i > 0 && (this.y < objects[i - 1].y + objects[i - 1].height) && o.y > objects[i - 1].y)) && this.y <= o.y + o.height) {
                        before = i;
                        break;
                    }
                }
            }

            if(before != -1) {
                this.pos = between( objects[before - 1]?.pos || START, objects[before].pos);
            } else {
                this.bringToFront();
            }
        }
        if(this.parent.layout != LayoutNone) {
            this.autoLayout = true;
        }
        this.parent.performLayout();
    }



    setDefaults() {
        if(this?.parent.defaultItemWidth) {
            this.width = this.parent.defaultItemWidth;
        }
        if(this?.parent.defaultItemHeight) {
            this.height = this.parent.defaultItemHeight;
        }
        this.autoLayout = true;
    }

    getConnectionSlots() {
        if(this.enableConnections) {
            return this._slots;  
        } else {
            return [];
        }
    }


    init() {
        super.init();
    }

    setPosition(x, y) {
        if(x !== undefined) {
            this.x = x;
        }
        if(y !== undefined) {
            this.y = y;
        }
    }

    translate(x, y) {
        if(x !== undefined) {
            this.x += x;
        }
        if(y !== undefined) {
            this.y += y;
        }
    }

    setSize(width, height) {
        this.width = width;
        this.height = height;
    }


    init() {
        super.init();
        this._y.set("transitions", new Y.Array());
        this._y.set("connections", new Y.Map());
    }

    get clientID() {
        return def(this._y.get("clientID"), "");
    }

    set clientID(value) {
        return this._y.set("clientID", value);
    }

    get strokeCollisionMargin() {
        return this.enableConnections ? StrokeCollisionMargin : 0;
    }

    get dragHandles() {
        return def(this._y.get("dragHandles"), [ "all" ]);
    }

    set dragHandles(value) {
        this._y.set("dragHandles", value);
    }

    get dragging() {
        return this._dragging;
    }

    set dragging(value) {
        this._dragging = value;
    }
    get webGLShader() {
        return this._y.get("webGLShader") || null;
    }

    set webGLShader(value) {
        this._y.set("webGLShader", value);
    }

    get alwaysOnTop() {
        return def(this._y.get("alwaysOnTop"), false);
    }

    set alwaysOnTop(value) {
        this._y.set("alwaysOnTop", value);
    }

    get dragMode() {
        return def(this._y.get("dragMode"), "default");
    }

    set dragMode(value) {
        this._y.set("dragMode", value);
    }


    get boxShadow() {
        return this._y.get("boxShadow") || null;
    }

    set boxShadow(value) {
        this._y.set("boxShadow", value);
    }

    get opacity() {
        return def(this._y.get("opacity"), 1.0);
    }

    set opacity(value) {
        if(Number.isNaN(value)) return;
        this._y.set("opacity", Math.max(Math.min(value, 1.0), 0));
    }


    get enableConnections() {
        return def(this._y.get("connectMode"), false); // TODO: false
    }

    set enableConnections(value) {
        return this._y.set("connectMode", value);
    }

    get code() {
        const codePage =  this._y.get("code");
        if(!codePage) {
            return null;
        }
        return ObjectFactory.fromY(this, codePage.get("yBox"), codePage.get("yObj"));
    }

    get type() {
        return this._y.get("type");
    }

    get name() {
        const t = this._y.get("name");
        if(t) {
            return t.toString();
        }
        return "";
    }

    set name(value) {
        this._y.set("name", value);      
    }

    // TODO: should we allow override in document?
    get snapping() {
        return this._snapping;
    }

    // this is used internally to position objects when rendering composites
    set snapping(value) {
        this._snapping = value;
    }

    get id() {
        return this._y.get("id");
    }


    get rotate() {
        if(this.containerID) {
            return 0;
        }
        return this._y.get("rotate") || 0;
    }

    set rotate(value) {
        this._y.set("rotate", value);
    }

    get scale() {
        //return this._y.get("scale") || 0;
        return this._scale || 0;
    }


    get _transitions() {
        return this._y.get("transitions");
    }

    addTransition(type) {
        const C = Transitions.get(type);
        if(!C) {
            throw new Error("Unknown transition: "+type);
        }
        const map = new Y.Map();
        const t = new C(this, map);
        t.init();
        this._transitions.push([map]);
        return t;
    }

    removeTransition(t) {
        for(let i = 0; i < this._transitions.length; i++) {
            if(this._transitions[i] == t._y) {
                this._transitions.delete(i, 1);
                break;
            }
        }
    }

    clearTransitions() {
        this._transitions.delete(0, this._transitions.length);
    }

    get transitions() {
        return this._transitions.map(t => ObjectFactory.fromY(this, t, t));
    }

    clone() {
        const yObj = this._y.clone();
        const yBox = this._ybox.clone();
        const id = uuid();
        // TODO: set position
        yObj.set("id", id);
        yBox.set("id", uuid());
        this.doc._objects.set(id, yObj);
        this.parent._objects.set(id, yBox);
    }


    get slide() {
        return this.parentOfType(Frame);
    }

    computeBounds(renderContext) {
        return this.getTransformedBounds(renderContext);
    }

    set scale(value) {
        this._scale = value;
        this.notify({ scale: value })
    }

    getTransformedBounds(renderContext) {
       
        const transform = new Transform(this.transform);

        if(renderContext) {
            this.animations.forEach(a => {
                a.apply(renderContext, transform, this);
            });
        }

        const bounds = new Bounds({
            x: this.x + transform.translateX,
            y: this.y + transform.translateY,
            width: this.width * transform.scaleX,
            height: this.height * transform.scaleY,
            rotate: this.transform.rotate
        });

        if(renderContext) {
            bounds.x *= renderContext.scale;
            bounds.y *= renderContext.scale;
            bounds.width *= renderContext.scale;
            bounds.height *= renderContext.scale;
        }
        return bounds;
    }
}


export class Frame extends SlideObject {
    constructor(parent, yObj, yBox) {
        super(parent, yObj, yBox);     
        this._fullScreen = false;
    }

    get droppable() {
        return true;
    }

    get slideName() {
        return this.title;
    }

    get fullScreen() {
        return this._fullScreen;
    }

    set fullScreen(value) {
        this._fullScreen = value;
        this.doc.fullScreenSlide = value ? this : null;
        this.notify({
            fullScreen: value
        });
    }

    get shapeOnly() {
        return def(this._y.get("shapeOnly"), false);
    }

    set shapeOnly(value) {
        this._y.set("shapeOnly", value);
    }

    get borderRadius() {
        return def(this._y.get("borderRadius"), 0);
    }

    set borderRadius(value) {
        this._y.set("borderRadius", value);
    }

    get borderColor() {
        return def(this._y.get("borderColor"), "#888888");
    }

    set borderColor(value) {
        return this._y.set("borderColor", value);
    }


    get borderWidth() {
        return def(this._y.get("borderWidth"),2);
    }

    set borderWidth(value) {
        return this._y.set("borderWidth", value);
    }

    get background() {
        return def(this._y.get("background"), { grid: true });
    }

    set background(value) {
        this._y.set("background", value);
    }

    setBackgroundColor(color) {
        this.background = { color };
    }

    setBackgroundImage(src) {
        this.background = { image: { src } };
    }

    setBackgroundImageNoun(noun) {
        this.background = { image: { noun } };
    }

    setBackgroundPdf(src, page = 1) {
        this.background = { pdf: { src, page } };
    }

    setBackgroundPdfNoun(noun, page = 1) {
        this.background = { pdf: { noun, page } };
    }

    setBackgroundCamera(participant, videoTrack) {
        this.background = { camera: { participant, videoTrack } };
    }
    
}

ObjectFactory.constructors.set("frame", Frame);

export class Grid extends Frame {
    constructor(parent, yObj, yBox) {
        super(parent, yObj, yBox);
    }

    init() {
        this.layout = LayoutGrid;
        this.background = null;
        this.paddingLeft = 20;
        this.paddingRight = 20;
        this.paddingTop = 20;
        this.paddingBottom = 20;
        super.init();
    }

    get rows() {
        return def(this._y.get("rows"), 2);
    }

    set rows(value) {
        this._y.set("rows", value);
        this.performLayout();
    }

    get columns() {
        return def(this._y.get("columns"), 2);
    }

    set columns(value) {
        this._y.set("columns", value);
        this.performLayout();
    }
}

ObjectFactory.constructors.set("grid", Grid);


export class Widget extends SlideObject {
    constructor(parent, yObj, yBox) {
        super(parent, yObj, yBox);
    }

    get typeName() {
        return "group";
    }

    get icon() {
        return "group-shape";
    }

    get _template() {
        return this._y.get("template");
    }

    set _template(value) {
        this._y.set("template", value);
    }

    get template() {
        return ObjectFactory.fromY(this.parent, this._template.get("yBox"), this._template.get("yObj"));
    }

    get maskGroup() {
        return def(this._y.get("maskGroup"), false);
    }

    set maskGroup(value) {
        this._y.set("maskGroup", value);
    }


    get layout() {
        return this.template.layout;
    }

    set layout(value) {
        this.template.layout = value;
    }

    init() {
        super.init();
        
        const yTemplate = new Y.Map();

        const yObj = new Y.Map();
        yObj.set("id", uuid());
        yObj.set("type", "frame");
        const yBox = new Y.Map();
        yBox.set("id", uuid());

        yTemplate.set("yObj", yObj);
        yTemplate.set("yBox", yBox);

        this._template = yTemplate;
        this.template.init();

        this.template.kind = "widget";
        this.template.background = { color: "#666666" };
        this.template.snap = 1;
    }
}

ObjectFactory.constructors.set("widget", Widget);


function regexIndexOf(string, regex, startpos) {
    var indexOf = string.substring(startpos || 0).search(regex);
    return (indexOf >= 0) ? (indexOf + (startpos || 0)) : indexOf;
}

function regexLastIndexOf(string, regex, startpos) {
    regex = (regex.global) ? regex : new RegExp(regex.source, "g" + (regex.ignoreCase ? "i" : "") + (regex.multiLine ? "m" : ""));
    if(typeof (startpos) == "undefined") {
        startpos = string.length;
    } else if(startpos < 0) {
        startpos = 0;
    }
    var stringToWorkWith = string.substring(0, startpos + 1);
    var lastIndexOf = -1;
    var nextStop = 0;
    var result;
    while((result = regex.exec(stringToWorkWith)) != null) {
        lastIndexOf = result.index;
        regex.lastIndex = ++nextStop;
    }
    return lastIndexOf;
}


export const ParagraphMark = "\u2028";


export class Text extends Frame {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
        this.maxHeight = Number.POSITIVE_INFINITY;
        this.textClock = 0;
        this._detectTextChanges();
    }

    setBackgroundColor(color) {
        this.background = { color };
    }

    remove(object, removeFromDoc = true, removeEmbed = true) {
        if(removeEmbed) {
            this.removeEmbed(object);
        }
        super.remove(object, removeFromDoc);
    }

    get icon() {
        return "layer-text";
    }

    init() {
        super.init();
        this._y.set("text", new Y.Text());
        this._detectTextChanges();
    }

    _detectTextChanges() {

        const text = this._text;
        if(text && text.observeDeep) {
            text.observeDeep(()=> {
                this.textClock++;
            });
        }
    }

    formatAtSelection(selection) {
        if(!selection) {
            return {
                ...this._text.toDelta()[0]?.attributes,
                font: this.font,
                style: this.fontStyle,
                weight: this.fontWeight
            }
        } else {
            const font = {
                font: this.font,
                style: this.fontStyle,
                weight: this.fontWeight
            };

            let pos = 0;
            const location = selection.from == selection.to ? selection.from - 1 : selection.from;
            const deltas = this._text.toDelta();
            for(let i = 0; i < deltas.length; i++) {
                const delta = deltas[i];
                if(location - pos < delta.insert.length || i == deltas.length - 1) {
                    return {
                        ...font,
                        ...delta.attributes,
                        ...this._tempAttributes
                    };

                } else {

                }
                pos += delta.insert.length;
            }
            return font;
        }
    }

    formatAtCursor() {
        const selection = this.selection;
        return this.formatAtSelection(selection);
    }

    format(start, length, attributes) {
        if(length == 0) {
            this._tempAttributes = { ...this._tempAttributes, ...attributes };
            this.notify({ _tempAttributes: this._tempAttributes});
        } else {
            this._text.format(start, length, attributes);
        }
    }

    selectWordAt(start, length = 0) {
        const text = this.text;
        let end = start + length;

        let ws = /[^\w]/

        if(ws.exec(text[start])) {
            start--;
        }
      
        start = regexLastIndexOf(text, ws, start) + 1;

        end = regexIndexOf(text, ws, Math.max(start, end - 1));
        if(end == -1) {
            end = text.length;
        } 
        this.select(start, end);
    }

    formatParagraph(start, length, attributes) {
        const text = this.text;
        let end = start + length;

        if(text[start] == "\r" || start == text.length) {
            start--;
        }
      
        start = text.lastIndexOf("\r", start) + 1;

        end = text.indexOf("\r", Math.max(start, end - 1));
        if(end == -1) {
            end = text.length;
        } else {
            end++;
        }

        if(start == end) {
            this.insertText(ParagraphMark);
            this.formatParagraph(start, length, attributes);
            return;
        }
        this._text.format(start, end - start, attributes);
    }    

    computePadding(renderContext) {
        return this.padding;
    }

    computeFontSize(renderContext) {
        const fontSize =  this._y.get("fontSize") || 0;
        // TODO: not sure this hidden context passing is great, requires renderers to maintain var
        if(!renderContext) {
            throw new Error("can only be returned during render pass");
        }
        return renderContext.calcExpression(fontSize);
    }


    computeOutlineWidth(renderContext) {
        const outlineWidth =  this._y.get("outlineWidth") || 0;
        // TODO: not sure this hidden context passing is great, requires renderers to maintain var
        if(!renderContext) {
            throw new Error("can only be returned during render pass");
        }
        return renderContext.calcExpression(outlineWidth);
    }

    get pageMode() {
        return def(this._y.get("pageMode"), false);
    }

    set pageMode(value) {
        return this._y.set("pageMode", value);
    }

    get allowEmbeds() {
        return def(this._y.get("allowEmbeds"), false);
    }

    set allowEmbeds(value) {
        return this._y.set("allowEmbeds", value);
    }

    get background() {
        return def(this._y.get("background"), null);
    }

    set background(value) {
        return this._y.set("background", value);
    }

    get autoResize() {
        return this._y.get("autoResize") || false;
    }

    set autoResize(value) {
        this._y.set("autoResize", value);
    }
  
    get padding() {
        return def(this._y.get("padding"), 0);
    }

    set padding(value) {
        this._y.set("padding", value);
    }

    get formatter() {
        return def(this._y.get("formatter"), null);
    }

    set formatter(value) {
        this._y.set("formatter", value);
        this._onTextUpdated();
    }

    get resizeMode() {
        return def(this._y.get("resizeMode"), "minHeight");
    }

    set resizeMode(value) {
        this._y.set("resizeMode", value);
    }

    get lineHeight() {
        return def(this._y.get("lineHeight"), 1.1);
    }

    set lineHeight(value) {
        this._y.set("lineHeight", value);
    }
    
    get fontName() {
        return def(this._y.get("fontName"), "Roboto");
    }

    set fontName(value) {
        this._y.set("fontName", value);
    }

    get fontSize() {
        return def(this._y.get("fontSize"), 20) || 20;
    }

    set fontSize(value) {
        this._y.set("fontSize", value);
    }
    
    get fontWeight() {
        return this._y.get("fontWeight") || 400;
    }

    set fontWeight(value) {
        this._y.set("fontWeight", value);
    }

    get fontStyle() {
        return this._y.get("fontStyle") || "normal";
    }

    set fontStyle(value) {
        this._y.set("fontStyle", value);
    }


    get color() {
        return this._y.get("color") || "#000000";
    }

    set color(value) {
        this._y.set("color", value);
    }

    get outlineWidth() {
        return this._y.get("outlineWidth") || null;
    }

    set outlineWidth(value) {
        this._y.set("outlineWidth", value);
    }

    get outlineColor() {
        return this._y.get("outlineColor") || null;
    }

    set outlineColor(value) {
        this._y.set("outlineColor", value);
    }


    get numbering() {
        return this._y.get("numbering") || null;
    }

    set numbering(value) {
        this._y.set("numbering", value);
    }

    get text() {
        const t = this._text;
        if(t) {
            return t.toString();
        }
        return "";
    }

    get droppable() {
        return !!this.pageMode;
    }

    get length() {
        return this._text.length;
    }

    set text(value) {
        let t = this._text; 
        if(t == undefined) {
            t = new Y.Text();
            this._y.set("text", t);
        }

        if(value && typeof(value) == "object") {
            t.delete(0, t.length);
            t.applyDelta(value.deltas);
        } else {
            t.delete(0, t.length);
            t.insert(0, value);
        }
        this._onTextUpdated();
    }

    // left, right, center
    get alignment() {
        return this._y.get("alignment") || "left";
    }

    set alignment(value) {
        this._y.set("alignment", value);
    }

    // left, right, center
    get verticalAlignment() {
        return this._y.get("verticalAlignment") || "top";
    }

    set verticalAlignment(value) {
        this._y.set("verticalAlignment", value);
    }

    // none, justify
    get justification() {
        return this._y.get("justification") || "none";
    }

    set justification(value) {
        this._y.set("justification", value);
    }
    
    insertText(c, format) {
        if(this.selection) {
            const from = this.selection.from;
            this._text.insert(this.selection.to, c, format);
            if(this._tempAttributes) {
                this.format(from, this.selection.from - from, { ...this._tempAttributes, ...format });
                this._tempAttributes = null;
            }
        } else {
            this._text.insert(this.text.length, c, format);
        }
        this._onTextUpdated();
    }

    replaceText(c, format) {
        if(this.selection) {
            this._removeEmbeds(this.selection.from, this.selection.to);
            const from = this.selection.from;
            if(this.formatAtCursor().embed) {
                this._tempAttributes = { embed: null };
            }
            this._text.insert(this.selection.to, c, format);
            this._text.delete(Math.min(this.selection.from, this.selection.to), Math.max(this.selection.to, this.selection.from) - Math.min(this.selection.to, this.selection.from) - c.length);
           
            if(this._tempAttributes) {
                this.format(from, this.selection.from - from, this._tempAttributes);
                this._tempAttributes = null;

            }
            this.select(this.selection.to, this.selection.to);
        } else {
            this._text.insert(this.text.length, c, format);
        }
        this._onTextUpdated();
    }


    hasEmbed(object) {
      
        let pos = 0;
        let found = false;

        const deltas = this._text.toDelta();
        for(let i = 0; i < deltas.length; i++) {
            const delta = deltas[i];
            if(delta.attributes?.embed == object.id) {
                found = true;
                break;
            }
            pos += delta.insert.length;
        }
        return found;
    }

    removeEmbed(object) {
      
        const font = {
            font: this.font,
            style: this.fontStyle,
            weight: this.fontWeight
        };

        let pos = 0;
        let removed = false;

        const deltas = this._text.toDelta();
        for(let i = 0; i < deltas.length; i++) {
            const delta = deltas[i];
            if(delta.attributes?.embed == object.id) {
                this._text.delete(pos, 1);
                removed = true;
                break;
            }
            pos += delta.insert.length;
        }
        return font;
        
    }

    backspace() {
        if(this.selection) {
            if(this.selection.from != this.selection.to) {
                this._removeEmbeds(this.selection.from, this.selection.to);
                this._text.delete(Math.min(this.selection.from, this.selection.to), Math.max(this.selection.to, this.selection.from) - Math.min(this.selection.to, this.selection.from));
          
            } else {
                this._removeEmbeds(this.selection.from - 1, this.selection.to);
                this._text.delete(this.selection.from - 1, 1);
                this.select(this.selection.from, this.selection.from);
            }
        } 
        this._onTextUpdated();       
    }

    getSelectedText() {
        if(this.selection) {
            const min = Math.min(this.selection.from, this.selection.to);
            const max = Math.max(this.selection.from, this.selection.to);
            
            return this.text.substr(min, max - min);
        } 
    }

    deleteSelectedText() {
        if(this.selection) {
            this._text.delete(Math.min(this.selection.from, this.selection.to), Math.max(this.selection.to, this.selection.from) - Math.min(this.selection.to, this.selection.from));
            this.select(this.selection.from, this.selection.from);
        }
    }

    select(from, to) {
        if(!this.selection || this.selection.from != from && this.selection.to != to) {
            this._tempAttributes = {};
        }
        if(arguments.length < 2) {
            super.select(...arguments);
        } else {
            const cursor = new Cursor(this.doc, Y.createRelativePositionFromTypeIndex(this._text, from), Y.createRelativePositionFromTypeIndex(this._text, to));        
            this.doc.cursors = [ cursor ];
        }
        this.notify({ selectionChanged: true });
    }

    selectAll() {
        this.select(0, this.text.length);
    }

    get selected() {
        if(this.selection) {
            return true;
        }
        return super.selected;
    }

    get selection() {        
        if(this.doc?.cursors) {
            for(let cursor of this.doc.cursors) {
                const from = Y.createAbsolutePositionFromRelativePosition(cursor.from, this.doc._y);
                if(!from) {
                    return null;
                }
                if(from.type == this._text) {
                    const to = Y.createAbsolutePositionFromRelativePosition(cursor.to, this.doc._y);
                    if(!to) {
                        return null;
                    }
                    if(to.type == this._text) {
                        return { from: from.index, to: to.index };      
                    }     
                }     
            }        
        }
        return null;
    }  
}

ObjectFactory.constructors.set("text", Text);

export const ConnectionSlotTop = "top";
export const ConnectionSlotLeft = "left";
export const ConnectionSlotBottom = "bottom";
export const ConnectionSlotRight = "right";

export class Shape extends Text {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get icon() {
        let shape;
        switch(this.shape) {
            case "rectangle":
                shape = "square";
                break;
            case "ellipse":
                shape = "circle"
                break;
            case "plus":
                shape = "square";
                break;
            default:
                shape = this.shape;
        }
        return "shape-"+shape; 
    }

    get typeName() {
        return this.shape;
    }

    computePadding(renderContext) {
        const bounds = this.getTransformedBounds(renderContext);
        if(this.shape == "rectangle") {
            return renderContext.calcExpression(this.padding || 0);
        }
        if(this.shape == "ellipse") {
            const d = Math.max(bounds.width);
            return (d - d/Math.sqrt(2)) / 2;
        }
        if(this.shape == "diamond") {
            const d = Math.max(bounds.height);
            return (d - d/Math.sqrt(2));
        }
        return super.computePadding(renderContext);
    }

    get shape() {
        return def(this._y.get("shape"), "rectangle")
    }

    set shape(value) {
        return this._y.set("shape", value);
    }    
 
    get backgroundColor() {
        return this._y.get("backgroundColor") || null;
    }

    set backgroundColor(value) {
        return this._y.set("backgroundColor", value);
    }


} 

ObjectFactory.constructors.set("shape", Shape);


export class Code extends SlideObject {
    constructor(parent, yObj, yBox) {
        super(parent, yObj, yBox);
    }

    init() {
        super.init();
        const t = new Y.Text("");
        this._y.set("text", t);
    }

    get icon() {
        return "code";
    }

    get theme() {
        return def(this._y.get("theme"), null);
    }

    set theme(value) {
        return this._y.set("theme", value);
    }

    get language() {
        return def(this._y.get("language"), "javascript");
    }

    set language(value) {
        return this._y.set("language", value);
    }

    get _text() {
        return this._y.get("text");
    }

    get text() {
        const t = this._text;
        if(t) {
            return t.toString();
        }
        return "";
    }
}

ObjectFactory.constructors.set("code", Code);

export class Sticky extends Shape {
    constructor(parent, yObj, yBox) {
        super(parent, yObj, yBox);
    }

    get icon() {
        return "note";
    }
}

ObjectFactory.constructors.set("sticky", Sticky);


export class Link extends Shape {
    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get url() {
        return this._y.get("url");
    }

    set url(value) {
        this._y.set("url", value);      
    }

    get title() {
        return this._y.get("title");
    }

    set title(value) {
        this._y.set("title", value);      
    }

    get image() {
        return this._y.get("image");
    }

    set image(value) {
        this._y.set("image", value);      
    }
}
ObjectFactory.constructors.set("link", Link);

export class MediaObject extends SlideObject {
    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get brightness() {
        return def(this._y.get("brightness"), 1.0);
    }

    set brightness(value) {
        return this._y.set("brightness", value);
    }

    get saturation() {
        return def(this._y.get("saturation"), 1.0);
    }

    set saturation(value) {
        return this._y.set("saturation", value);
    }

    get contrast() {
        return def(this._y.get("contrast"), 1.0);
    }

    set contrast(value) {
        return this._y.set("contrast", value);
    }

    get red() {
        return def(this._y.get("red"), 1.0);
    }

    set red(value) {
        return this._y.set("red", value);
    }

    get green() {
        return def(this._y.get("green"), 1.0);
    }

    set green(value) {
        return this._y.set("green", value);
    }


    get blue() {
        return def(this._y.get("blue"), 1.0);
    }

    set blue(value) {
        return this._y.set("blue", value);
    }

}

export class Prompt extends SlideObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get context() {
        return this._y.get("context");
    }

    set context(value) {
        return this._y.set("context", value);
    }

    get prompt() {
        return this._y.get("prompt");
    }

    set prompt(value) {
        return this._y.set("prompt", value);
    }

    get response() {
        return this._y.get("response");
    }

    set response(value) {
        return this._y.set("response", value);
    }
} 

ObjectFactory.constructors.set("prompt", Prompt);


export class Image extends MediaObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get icon() {
        return "layer-image";
    }

    get preferRetainAspectRatio() {
        return true;
    }

    get src() {
        return this._y.get("src");
    }

    set src(value) {
        return this._y.set("src", value);
    }

    get alt() {
        return this._y.getText("alt");
    }

    set alt(value) {
        return this._y.set("alt", value);
    }

    get noun() {
        return this._y.get("noun");
    }

    set noun(value) {
        return this._y.set("noun", value);
    }
} 

ObjectFactory.constructors.set("image", Image);

export class IFrame extends MediaObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get icon() {
        return "layer-frame";
    }

    get preferRetainAspectRatio() {
        return true;
    }

    get src() {
        return this._y.get("src");
    }

    set src(value) {
        return this._y.set("src", value);
    }

    get alt() {
        return this._y.getText("alt");
    }

    set alt(value) {
        return this._y.set("alt", value);
    }
} 

ObjectFactory.constructors.set("iframe", IFrame);

export class Chat extends SlideObject {
    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    init() {
        super.init();

        const textbox = this.addText();
        textbox.name = "text";
        textbox.width = this.width;
        textbox.padding = 10;
        textbox.fontSize = 20;
        const textboxHeight = textbox.padding * 2 + textbox.fontSize;
        textbox.minHeight = textboxHeight;
        textbox.x = 0;
        textbox.y = textbox.height - textbox.minHeight;
        textbox.constrain(true);
        textbox.constrainBottom = true;
  

        const chatWindow = this.addFrame();
        chatWindow.x = 0;
        chatWindow.y = 0;
        chatWindow.width = this.width;
        chatWindow.height = this.height;
        chatWindow.constrain(true)
    }
}

ObjectFactory.constructors.set("chat", Chat);

export class Annotation extends SlideObject {
    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    init() {
        super.init();
    }
} 
ObjectFactory.constructors.set("annotation", Annotation);

export class Comment {
    constructor(parent, yObj) {
        this._parent = parent;
        this._y = yObj;
    }
    init() {

    }

    get parent() {
        return this._parent;
    }

    get id() {
        return def(this._y.get("id"), null);
    }

    set id(value){
        this._y.set("id", value);
    }

    get transcribed() {
        return def(this._y.get("transcribed"), false);
    }

    set transcribed(value){
        this._y.set("transcribed", value);
    }

    get createdBy() {
        return def(this._y.get("createdBy"), null);
    }

    set createdBy(value){
        this._y.set("createdBy", value);
    }


    get text() {
        return def(this._text, null);
    }
    
    set text(value){
        this._y.set("text", value);
    }

    get reference() {
        return def(this._y.get("reference"), null);
    }

    set reference(value) {
        this._y.set("reference", value);
    }

    get media() {
        return def(this._y.get("media"), null);
    }

    set media(value) {
        this._y.set("media", value);
    }

    get createdAt() {
        return def(this._y.get("createdAt"), null);
    }
    
    set createdAt(value){
        this._y.set("createdAt", value);
    }
}

ObjectFactory.constructors.set("comment", Comment);

export class Pdf extends SlideObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get icon() {
        return "layer-pdf";
    }

    get preferRetainAspectRatio() {
        return true;
    }

    get src() {
        return this._y.get("src");
    }

    set src(value) {
        return this._y.set("src", value);
    }

    get noun() {
        return this._y.get("noun");
    }

    set noun(value) {
        return this._y.set("noun", value);
    }

    get page() {
        return this._y.get("page") || 1;
    }

    set page(value) {
        return this._y.set("page", value);
    }

    get pageCount() {
        return this._y.get("pageCount") || 100;
    }

    set pageCount(value) {
        return this._y.set("pageCount", value);
    }

    nextPage() {
        this.page = ((this.page) % this.pageCount) + 1;
    }


    prevPage() {
        this.page = this.page == 1 ? (this.pageCount - 1) : (this.page - 1);
    }


    get alt() {
        return this._y.getText("alt");
    }

    set alt(value) {
        return this._y.set("alt", value);
    }
} 

ObjectFactory.constructors.set("pdf", Pdf);

const LineShapeStraight = ("LineShapeStraight");
const LineShapeCurved = ("LineShapeCurved");

export { LineShapeCurved, LineShapeStraight };

export class Line extends SlideObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
        this.minWidth = -1000000;
        this.minHeight = -1000000;
    }

    get icon() {
        return "layer-line";
    }

    get snapping() {
        return !this.dragging;
    }

    get from() {
        return this._y.get("from") || null;
    }

    set from(value) {
        return this._y.set("from", value);
    }
    get shape() {
        return this._y.get("shape") || ((this.from || this.to) ? LineShapeCurved : LineShapeStraight);
    }

    set shape(value) {
        return this._y.set("shape", value);
    }


    get cp1x() {
        const cp1x = this._y.get("cp1x");
        if(cp1x === undefined) {
            if(!this.from) {
                return 0.0;
            }
            switch(this.fromPosX) {
                case -1:
                    return 0.6;
                case 1:
                    return 0.6;
                case 0:
                    return 0;
            }
        }
        return cp1x;
    }

    set cp1x(value) {
        return this._y.set("cp1x", value);
    }


    get cp1y() {
        const cp1y = this._y.get("cp1y");
        if(cp1y === undefined) {
            if(!this.from) {
                return 0.0;
            }
            switch(this.fromPosY) {
                case -1:
                    return 0.6;
                case 1:
                    return 0.6;
                case 0:
                    return 0;
            }
        }
        return cp1y;
    }

    set cp1y(value) {
        return this._y.set("cp1y", value);
    }

    get cp2x() {
        const cp2x = this._y.get("cp2x");
        if(cp2x === undefined) {
            if(!this.to) {
                return 1.0;
            }
            switch(this.toPosX) {
                case -1:
                    return 0.4;
                case 1:
                    return 0.4;
                case 0:
                    return 1.0;
            }
        }
        return cp2x;
    }

    set cp2x(value) {
        return this._y.set("cp2x", value);
    }


    get cp2y() {
        const cp2y = this._y.get("cp2y");
        if(cp2y === undefined) {
            if(!this.to) {
                return 1.0;
            }
            switch(this.toPosY) {
                case -1:
                    return 0.4;
                case 1:
                    return 0.4;
                case 0:
                    return 1.0;
            }
        }
        return cp2y;
    }

    set cp2y(value) {
        return this._y.set("cp2y", value);
    }



    get toPosX() {
        return this._y.get("toPosX") || 0;
    }

    set toPosX(value) {
        return this._y.set("toPosX", value);
    }

    get toPosY() {
        return this._y.get("toPosY") || 0;
    }

    set toPosY(value) {
        return this._y.set("toPosY", value);
    }


    get fromPosX() {
        return this._y.get("fromPosX") || 0;
    }

    set fromPosX(value) {
        return this._y.set("fromPosX", value);
    }

    get fromPosY() {
        return this._y.get("fromPosY") || 0;
    }

    set fromPosY(value) {
        return this._y.set("fromPosY", value);
    }


    get to() {
        return this._y.get("to") || null;
    }

    set to(value) {
        return this._y.set("to", value);
    }

    get strokeStyle() {
        return this._y.get("strokeStyle") || "#f00";
    }

    set strokeStyle(value) {
        return this._y.set("strokeStyle", value);
    }

    get lineWidth() {
        return this._y.get("lineWidth") || 4;
    }

    set lineWidth(value) {
        return this._y.set("lineWidth", value);
    }

    // none | arrow
    get startCapStyle() {
        return this._y.get("startCapStyle") || "none";
    }

    set startCapStyle(value) {
        return this._y.set("startCapStyle", value);
    }

    // none | arrow
    get endCapStyle() {
        return this._y.get("endCapStyle") || "none";
    }

    set endCapStyle(value) {
        return this._y.set("endCapStyle", value);
    }
} 

ObjectFactory.constructors.set("line", Line);

export class Video extends MediaObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get preferRetainAspectRatio() {
        return true;
    }

    get src() {
        return this._y.get("src");
    }

    set src(value) {
        return this._y.set("src", value);
    }

    get noun() {
        return this._y.get("noun");
    }

    set noun(value) {
        return this._y.set("noun", value);
    }


    get alt() {
        return this._y.getText("alt");
    }

    set alt(value) {
        return this._y.set("alt", value);
    }
    
    get playing() {
        return this._y.get("playing") || false;
    }

    set playing(value) {
        return this._y.set("playing", value);
    }

} 

ObjectFactory.constructors.set("video", Video);

export class File extends SlideObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get preferRetainAspectRatio() {
        return true;
    }

    get src() {
        return this._y.get("src");
    }

    set src(value) {
        return this._y.set("src", value);
    }

    get noun() {
        return this._y.get("noun");
    }

    set noun(value) {
        return this._y.set("noun", value);
    }
} 

ObjectFactory.constructors.set("file", File);

export class RiveAnimation extends Layer {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get url() {
        return this._y.get("url");
    }

    set url(value) {
        return this._y.set("url", value);
    }


    get data() {
        return this._y.get("data");
    }

    set data(value) {
        return this._y.set("data", value);
    }


} 

ObjectFactory.constructors.set("rive", RiveAnimation);


export class Audio extends SlideObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get feedFM() {
        return true;
    }
    
    get src() {
        return this._y.get("src");
    }

    set src(value) {
        return this._y.set("src", value);
    }

    get visualization() {
        return this._y.get("visualization") || "circle";
    }

    set visualization(value) {
        return this._y.set("visualization", value);
    }


    get volume() {
        // cap at 1.0
        return Math.min(1.0, this._y.get("volume") != undefined ? this._y.get("volume") : 1.0);
    }

    set volume(value) {
        return this._y.set("volume", Math.max(Math.min(1.0, value),0));
    }

    get noun() {
        return this._y.get("noun");
    }

    set noun(value) {
        return this._y.set("noun", value);
    }


    get alt() {
        return this._y.getText("alt");
    }

    set alt(value) {
        return this._y.set("alt", value);
    }
    
    get playing() {
        return this._y.get("playing") || false;
    }

    set playing(value) {
        return this._y.set("playing", value);
    }

} 

ObjectFactory.constructors.set("audio", Audio);


export class Lottie extends SlideObject {

    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get typeName() {
        return "sticker";
    }

    get icon() {
        return "sticker";
    }

    get src() {
        return this._y.get("src");
    }

    set src(value) {
        return this._y.set("src", value);
    }

    get alt() {
        return this._y.getText("alt");
    }

    set alt(value) {
        return this._y.set("alt", value);
    }
} 

ObjectFactory.constructors.set("lottie", Lottie);

export class Camera extends MediaObject {
    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    
    }

    get icon() {
        return "add-source-live";
    }

    get removeColor() {
        return this._y.get("removeColor") || null;
    }

    set removeColor(value) {
        this._y.set("removeColor", value);
    }

    get removeColorSpill() {
        return this._y.get("removeColorSpill") || 0.5;
    }

    set removeColorSpill(value) {
        this._y.set("removeColorSpill", value);
    }

    get removeColorSimilarity() {
        return this._y.get("removeColorSimilarity") || .2;
    }

    set removeColorSimilarity(value) {
        this._y.set("removeColorSimilarity", value);
    }

    get removeColorSmoothness() {
        return this._y.get("removeColorSmoothness") || 0.1;
    }

    set removeColorSmoothness(value) {
        this._y.set("removeColorSmoothness", value);
    }

    get participant() {
        return this._y.get("participant") || null;
    }

    set participant(value) {
        console.log("Setting participant to", value)
        this._y.set("participant", value);
    }

    get scope() {
        return this._y.get("scope") || "document";
    }

    set scope(value) {
        this._y.set("scope", value);
    }

    // null | ellipse
    get mask() {
        return this._y.get("mask") || null;
    }

    set mask(value) {
        this._y.set("mask", value);
    }

    get videoTrack() {
        return this._y.get("videoTrack") || null;
    }

    set videoTrack(value) {
        this._y.set("videoTrack", value);
    }
} 
ObjectFactory.constructors.set("camera", Camera);


export class ImageCollection extends Camera {

    constructor(slide, yObj) {
        super(slide, yObj);
        this.selectedImage = 0;
        this._camera = null;
    }

    get icon() {
        return  "layer-images";
    }


    init() {
        if(!this._y.get("images")) {
            this._y.set("images", new Y.Array());
        }        
        super.init();
    }

    dropObject(o) {
        if(o instanceof Image) {
            this.addImage({ src: o.src, noun: o.noun });
            o.delete();
        }
    }

    get src() {
        const img = this.imageAt(this.selectedImage);
        return img && img.src
    }

    get noun() {
        const img = this.imageAt(this.selectedImage);
        return img && img.noun
    }

    get _images() {
        return this._y.get("images");
    }

    get imageCount() {
        return this._images.length;
    }

    imageAt(i) {
        let _y = this._images.get(i);
        if(!_y) {
            return undefined;
        }
        return {
            get src() {
                return _y.get("src");
            },

            set src(value) {
                _y.doc.transact(()=> {
                    return _y.set("src", value);
                });
            },
        
            get noun() {
                return _y.get("noun");
            },
        
            set noun(value) {
                _y.doc.transact(()=> {
                    return _y.set("noun", value);
                });
            }
        }
    }

    addImage({ src, noun }) {
        const a = new Y.Map();
        if(src) {
            a.set("src", src);
        }
        if(noun) {
            a.set("noun", noun);
        }
        this._images.push([a]);
    }

    removeImageAt(i) {
        this._images.delete(i, 1);
    }

    get autoAdvance() {
        return this._y.get("autoAdvance") || false;
    }

    set autoAdvance(value) {
        return this._y.set("autoAdvance", value);
    }

    get currentImage() {
        return this._y.get("currentImage") || 0;
    }

    set currentImage(value) {
        return this._y.set("currentImage", value);
    }

    nextImage() {
        this.currentImage = (this.currentImage+1) % this._images.length;
    }


    prevImage() {
        this.currentImage = this.currentImage == 0 ? (this._images.length - 1) : (this.currentImage - 1);
    }


    get alt() {
        return this._y.getText("alt");
    }

    set alt(value) {
        return this._y.set("alt", value);
    }
} 

ObjectFactory.constructors.set("imageCollection", ImageCollection);

export class CameraGrid extends SlideObject {
    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get icon() {
        return "add-source-grid-live";
    }

    get rows() {
        return this._y.get("rows") || null;
    }

    set rows(value) {
        this._y.set("rows", value);
    }

    get columns() {
        return this._y.get("columns") || null;
    }

    set columns(value) {
        this._y.set("columns", value);
    }


    get margin() {
        return def(this._y.get("margin"), 20);
    }

    set margin(value) {
        this._y.set("margin", value);
    }

    get scope() {
        return this._y.get("scope") || "document";
    }

    set scope(value) {
        this._y.set("scope", value);
    }

    get hideDominantParticipant() {
        return this._y.get("hideDominantParticipant") || false;
    }

    set hideDominantParticipant(value) {
        this._y.set("hideDominantParticipant", value);
    }
   

    get dominantParticipantOutlineWidth() {
        return this._y.get("dominantParticipantOutlineWidth") || null;
    }

    set dominantParticipantOutlineWidth(value) {
        this._y.set("dominantParticipantOutlineWidth", value);
    }

    get dominantParticipantOutlineColor() {
        return this._y.get("dominantParticipantOutlineColor") || null;
    }

    set dominantParticipantOutlineColor(value) {
        this._y.set("dominantParticipantOutlineColor", value);
    }

    // null | ellipse
    get mask() {
        return this._y.get("mask") || null;
    }

    set mask(value) {
        this._y.set("mask", value);
    }
} 
ObjectFactory.constructors.set("cameraGrid", CameraGrid);

export class CameraRing extends SlideObject {
    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get slots() {
        return this._y.get("slots") || 4;
    }

    set slots(value) {
        this._y.set("slots", value);
    }

    get mask() {
        return this._y.get("mask") || null;
    }

    set mask(value) {
        this._y.set("mask", value);
    }


    get rows() {
        return this._y.get("rows") || 1;
    }

    set rows(value) {
        this._y.set("rows", value);
    }

    get scope() {
        return this._y.get("scope") || "document";
    }

    set scope(value) {
        this._y.set("scope", value);
    }

    get hideDominantParticipant() {
        return this._y.get("hideDominantParticipant") || false;
    }

    set hideDominantParticipant(value) {
        this._y.set("hideDominantParticipant", value);
    }
   

    get dominantParticipantOutlineWidth() {
        return this._y.get("dominantParticipantOutlineWidth") || null;
    }

    set dominantParticipantOutlineWidth(value) {
        this._y.set("dominantParticipantOutlineWidth", value);
    }

    get dominantParticipantOutlineColor() {
        return this._y.get("dominantParticipantOutlineColor") || null;
    }

    set dominantParticipantOutlineColor(value) {
        this._y.set("dominantParticipantOutlineColor", value);
    }

    // null | ellipse
    get mask() {
        return this._y.get("mask") || null;
    }

    set mask(value) {
        this._y.set("mask", value);
    }
} 
ObjectFactory.constructors.set("cameraRing", CameraRing);


export class Screen extends MediaObject {
    constructor(doc, yObj, yBox) {
        super(doc, yObj, yBox);
    }

    get icon() {
        return "add-source-screen";
    }

    get scope() {
        return this._y.get("scope") || "document";
    }

    set scope(value) {
        this._y.set("scope", value);
    }

    get participant() {
        return this._y.get("participant");
    }

    set participant(value) {
        this._y.set("participant", value);
    }

    get videoTrack() {
        return this._y.get("videoTrack") || null;
    }

    set videoTrack(value) {
        this._y.set("videoTrack", value);
    }

    get cropX() {
        return this._y.get("cropX") || 0;
    }

    set cropX(value) {
        this._y.set("cropX", value);
    }

    get cropY() {
        return this._y.get("cropY") || 0;
    }

    set cropY(value) {
        this._y.set("cropY", value);
    }

    get scaleX() {
        return this._y.get("cropHeight") || 1;
    }

    set scaleX(value) {
        this._y.set("cropHeight", value);
    }

    get scaleY() {
        return this._y.get("cropWidth") || 1;
    }

    set scaleY(value) {
        this._y.set("cropWidth", value);
    }
} 
ObjectFactory.constructors.set("screen", Screen);


class Cursor {
    constructor(doc, from, to) {
        this._from = from;
        this._to = to;
        this._doc = doc;
    }

    get doc() {
        return this._doc;        
    }
    
    get from() {
        return this._from;
    }

    get to() {
        return this._to;
    }
}
