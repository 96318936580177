import { ObjectCommand, ObjectsCommandGroup } from "./command.js";

const SetSource = Symbol("SetSource");
export { SetSource }

export class SetSourceCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetSource, text: "Set Source", ...options });

        this.const("src", options.src);
        this.const("noun", options.noun);
    }

    execute(ctx) {
        this.object.src = this.src;
        this.object.noun = this.noun;
    }
}