export class Command {
    constructor({ id, text }) {
        this.id = id;
        this.text = text;
    }

    // TODO eliminate usage
    const(name, value) {
        this[name] = value;
    }
    // TODO eliminate usage
    prop(name, value) {
        this[name] = value;
    }

    execute(ctx) {
    }
}
