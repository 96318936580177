import { ObjectCommand, ObjectsCommand } from "./command.js";

const LeftAlignObjects = Symbol("LeftAlignObjects");
export { LeftAlignObjects }

export class LeftAlignObjectsCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: LeftAlignObjects, text: "Left Align Objects", ...options });
    }

    execute(ctx) {
        const minX = this.objects.reduce((p,c) => Math.min(c.x,p), this.objects[0].x);
     
        this.objects.forEach(o => {
            o.x = minX;
        });
    }
} 


const RightAlignObjects = Symbol("RightAlignObjects");
export { RightAlignObjects }

export class RightAlignObjectsCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: RightAlignObjects, text: "Right Align Objects", ...options });
    }

    getState() {
       return this.objects && this.objects.length > 1 ? Enabled : Disabled;
    }

    execute(ctx) {
        const maxX = this.objects.reduce((p,c) => Math.max(c.x + c.width,p), this.objects[0].x);
        
        this.objects.forEach(o => {
            o.x = maxX - o.width;
        });
    }
} 



const HorizontalCenterAlignObjects = Symbol("HorizontalCenterAlignObjects");
export { HorizontalCenterAlignObjects }

export class HorizontalCenterAlignObjectsCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: HorizontalCenterAlignObjects,  text: "Center Align Objects (Horizontal)", ...options });

    }


    execute(ctx) {
        const minX = this.objects.reduce((p,c) => Math.min(c.x,p), this.objects[0].x);
        const maxX = this.objects.reduce((p,c) => Math.max(c.x + c.width,p), this.objects[0].x);
    
        this.objects.forEach(o => {
            o.x = (minX + maxX)/2 - o.width / 2;
        });
    }
} 





const TopAlignObjects = Symbol("TopAlignObjects");
export { TopAlignObjects }

export class TopAlignObjectsCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: TopAlignObjects, text: "Top Align Objects", ...options });
    }

    execute(ctx) {
       const minY = this.objects.reduce((p,c) => Math.min(c.y,p), this.objects[0].y);

        this.objects.forEach(o => {
            o.y = minY;
        });
    }
} 


const BottomAlignObjects = Symbol("BottomAlignObjects");
export { BottomAlignObjects }

export class BottomAlignObjectsCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: BottomAlignObjects, text: "Bottom Align Objects", ...options });
    }
    execute(ctx) {
        const maxY = this.objects.reduce((p,c) => Math.max(c.y + c.height,p), this.objects[0].y);

        this.objects.forEach(o => {
            o.y = maxY - o.height;
        });
    }
} 



const VerticalCenterAlignObjects = Symbol("VerticalCenterAlignObjects");
export { VerticalCenterAlignObjects }

export class VerticalCenterAlignObjectsCommand extends ObjectsCommand {
    constructor(options) {
        super({ id: VerticalCenterAlignObjects, text: "Center Align Objects (Vertical)", ...options });
    }

    getState() {
        return this.objects && this.objects.length > 1 ? Enabled : Disabled;
    }

    execute(ctx) {
        const minY = this.objects.reduce((p,c) => Math.min(c.y,p), this.objects[0].y);
        const maxY = this.objects.reduce((p,c) => Math.max(c.y + c.height,p), this.objects[0].y);

        this.objects.forEach(o => {
            o.y = (minY + maxY)/2 - o.height / 2;
        });
    }
} 
