import { ObjectCommand } from "./command.js";

const Delete = Symbol("Delete");
export { Delete }

export class DeleteCommand extends ObjectCommand {
    constructor(options) {
        super({ id: Delete,  text: "Delete", ...options });
    }
    
    execute(ctx) {
        this.object.delete();
    }
} 

const Paste = Symbol("Paste");
export { Paste }

export class PasteCommand extends ObjectCommand {
    constructor(options) {
        super({ id: Paste, icon: "paste", text: "Paste", ...options });

        this.const("data", options.data);
    }

    executeTransaction(ctx) {        
        for(let json of this.data) {
            // format of each object = { object, box }
            this.object.addJSONObject(json, false);
        }
    }
}


const SendToBack = Symbol("SendToBack");
export { SendToBack }

export class SendToBackCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SendToBack, text: "Send To Back", ...options });
    }

    execute(ctx) {
        this.object.sendToBack();
    }
} 


const ChangePosition = Symbol("ChangePosition");
export { ChangePosition }

export class ChangePositionCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ChangePosition, text: "Change Position", ...options });

        this.const("index", options.index);
    }

    execute(ctx) {
        this.object.changePosition(this.index);
    }
} 


const BringToFront = Symbol("BringToFront");
export { BringToFront }

export class BringToFrontCommand extends ObjectCommand {
    constructor(options) {
        super({ id: BringToFront, text: "Bring to Front", ...options });
    }

    execute(ctx) {
        this.object.bringToFront();
    }
} 
