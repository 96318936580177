import { ObjectCommand } from "./command.js";

const shapes = [ "rectangle", "ellipse", "diamond" ];

const ChangeShape = Symbol("ChangeShape");
export { ChangeShape }

export class ChangeShapeCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ChangeShape, text: "Change Shape", ...options });
    }

    execute(ctx) {
        this.object.shape = shapes[(shapes.indexOf(this.object.shape) + 1) % shapes.length];

    }
} 


const SetRiveAnimationUrl = Symbol("SetRiveAnimationUrl");
export { SetRiveAnimationUrl }

export class SetRiveAnimationUrlCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetRiveAnimationUrl, text: "Set Url", ...options });       
        this.const("url", options.url);
    }

    execute(ctx) {
        this.object.url = this.url;
    }
}


const SetRiveAnimationData = Symbol("SetRiveAnimationData");
export { SetRiveAnimationData }

export class SetRiveAnimationDataCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetRiveAnimationData, text: "Set Data", ...options });       
        this.const("data", options.data);
    }

    execute(ctx) {
        this.object.data = this.data;
    }
}