import { ObjectCommand, ObjectsCommandGroup } from "./command.js";
import * as Y from "yjs";
import uuid from "timu-uuid";
import { END, START, between } from "../../buffer.js";


const AddMessage = Symbol("AddMessage");
export { AddMessage }

export class AddMessageCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddMessage, text: "Add Message", ...options });
        this.const("text", options.text);
        this.const("createdAt", options.createdAt);
        this.const("createdBy", options.createdBy);
    }

    executeTransaction(ctx) {
        const message = new Y.Map();
        const id = this.messageID || uuid();
        message.set("id", id);
        message.set("createdAt", this.createdAt);
        message.set("createdBy", this.createdBy);
        message.set("text", this.text);
        this.object._y.getArray("messages").insert(0, [ message ]);
    }
}

const AddContact = Symbol("AddContact");
export { AddContact }

export class AddContactCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddContact, text: "Add Contact", ...options });

        this.const("contactID", options.contactID);
        this.const("userID", options.userID);
        this.const("createdAt", options.createdAt);
        this.const("firstName", options.firstName);
        this.const("lastName", options.lastName);
        this.const("email", options.email);
        this.const("mobilePhone", options.mobilePhone);
    }

    executeTransaction(ctx) {
        const contact = new Y.Map();
        const id = this.contactID || uuid();

        contact.set("id", id);
        contact.set("userID", this.userID);
        contact.set("createdAt", this.createdAt);
        contact.set("firstName", this.firstName);
        contact.set("lastName", this.lastName);
        contact.set("email", this.email);
        contact.set("mobilePhone", this.mobilePhone);
        this.object._y.getMap("contacts").set(id, contact);
    }
}


const SetContactIdentity = Symbol("SetContactIdentity");
export { SetContactIdentity }

export class SetContactIdentityCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetContactIdentity, text: "Set Contact Identity", ...options });
        this.const("contactID", options.contactID);
        this.const("identityID", options.identityID);
    }

    executeTransaction(ctx) {
        const contact = this.object._y.getMap("contacts").get(this.contactID);
        if(!contact) {
            throw new Error("Unknown contact ID")
        }
        contact.set("identityID", this.identityID);
    }
}

const AcceptEmailRequest = Symbol("AcceptEmailRequest");
export { AcceptEmailRequest }

export class AcceptEmailRequestCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AcceptEmailRequest, text: "Accept Email Request", ...options });
        this.const("email", options.email);
       }

    executeTransaction(ctx) {
        const request = this.object._y.getMap("emailRequests").get(this.email);
        if(request) {

            const firstName = request["firstName"];
            const lastName = request["lastName"];
            const email = request["email"];
            const mobilePhone = request["mobilePhone"];
            const userID = request["userID"];

            const contact = new Y.Map();
            const id = uuid();
            contact.set("id", id);
            contact.set("createdAt", new Date().toISOString());
            contact.set("firstName", firstName);
            contact.set("lastName", lastName);
            contact.set("email", email);
            contact.set("mobilePhone", mobilePhone);
            contact.set("userID", userID)
            this.object._y.getMap("contacts").set(id, contact);

            this.object._y.getMap("emailRequests").delete(this.email);
        } else {
            console.error("Unable to find email request "+this.email);
        }
    }
}


const DeclineEmailRequest = Symbol("DeclineEmailRequest");
export { DeclineEmailRequest }

export class DeclineEmailRequestCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DeclineEmailRequest, text: "Decline Email Request", ...options });
        this.const("email", options.email);
       }

    executeTransaction(ctx) {
        const request = this.object._y.getMap("emailRequests").delete(this.email);
    }
}

const MarkNotificationRead = Symbol("MarkNotificationRead");
export { MarkNotificationRead }

export class MarkNotificationReadCommand extends ObjectCommand {
    constructor(options) {
        super({ id: MarkNotificationRead, text: "Mark Notification Read", ...options });
        this.const("id", options.id);
    }

    executeTransaction(ctx) {
        const notifications = this.doc.getArray("notifications");
        for(var i = 0; i < notifications.length; i++) {
            const notification = notifications.get(i);
            if(notification.get("id") == this.id) {
                notification.set("read", true);
                break;
            }
        }
    }
}


const PinRecentContact = Symbol("PinRecentContact");
export { PinRecentContact }

export class PinRecentContactCommand extends ObjectCommand {
    constructor(options) {
        super({ id: PinRecentContact, text: "Pin Recent", ...options });
        this.const("contactID", options.contactID);
        this.const("order", options.order);        
    }

    executeTransaction(ctx) {        
        this.object._y.getMap("pinnedContacts").set(this.contactID, this.order);
    }
}



const AddChatMessage = Symbol("AddChatMessage");
export { AddChatMessage }

export class AddChatMessageCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddChatMessage, text: "Comment", ...options });
        this.text = options.text;
        this.createdAt = options.createdAt;
        this.createdBy = options.createdBy;
        this.messageID = options.messageID;
        this.annotation = options.annotation;
        this.media = options.media;
    }

    executeTransaction(ctx) {
        const message = new Y.Map();
        message.set("id", this.messageID || uuid());
        message.set("text", this.text);
        message.set("createdAt", this.createdAt || new Date().toISOString());
        message.set("createdBy", this.createdBy);  
        if(this.media) {
            message.set("media", this.media);
        }
        if(this.annotation) {     
            message.set("annotation", this.annotation); 
        }
        this.object._y.getArray("messages").insert(0, [message]);
    }
}

const AddRecentChat = Symbol("AddRecentChat");
export { AddRecentChat }

export class AddRecentChatCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddRecentChat, text: "Add Recent", ...options });
        this.const("chatID", options.chatID);
        this.const("timestamp", options.timestamp);
        this.const("ifNotFound", options.ifNotFound);
        this.const("name", options.name);
        this.const("lastMessage", options.lastMessage);        
    }

    executeTransaction(ctx) {
        if(!this.ifNotFound || !this.object._y.getMap("recentChats").get(this.chatID)) {
            this.object._y.getMap("recentChats").set(this.chatID, { timestamp: this.timestamp, name: this.name, lastMessage: this.lastMessage }) ;
        }
    }
}

const MoveShelfEdges = Symbol("MoveShelfEdges");
export class MoveShelfEdgesCommand extends ObjectCommand {
    constructor(options) {
        super({ id: MoveShelfEdges, text: "Move Shelf Edges", ...options });     
        this.const("axis", options.axis);
        this.const("position", options.position);        
        this.const("newPosition", options.newPosition);
    }

    executeTransaction(ctx) {        
        if(this.axis == "x") {
            for(var v of this.object.slots.values()) {            
                var x = v.get("x");
                var width = v.get("width");
                var right = x + width;

                if(x == this.position) {
                    v.set("x", this.newPosition);
                    v.set("width", width - (this.newPosition - this.position));
                }
                if(right == this.position) {
                    v.set("width", this.newPosition - x);
                }            
            }
        }
        if(this.axis == "y") {
            for(var v of this.object.slots.values()) {            
                var y = v.get("y");
                var height = v.get("height");
                var bottom = y + height;

                if(y == this.position) {
                    v.set("y", this.newPosition);
                    v.set("height", height - (this.newPosition - this.position));
                }
                if(bottom == this.position) {
                    v.set("height", this.newPosition - y);
                }            
            }
        }
    }
}

const templates = new Map();

templates.set("blank", (document, params) => {

    const newSlot = new Y.Map();
    const id =  uuid();
    newSlot.set("id",id);
    newSlot.set("x", 0);
    newSlot.set("y", 0);        
    newSlot.set("width", 900);
    newSlot.set("height", 600);
    
    document.slots.set(id, newSlot);
});


templates.set("widescreen", (document, params) => {

    const newSlot = new Y.Map();
    const id =  uuid();
    newSlot.set("id",id);
    newSlot.set("x", 0);
    newSlot.set("y", 0);        
    newSlot.set("width", 1600);
    newSlot.set("height", 700);
    
    document.slots.set(id, newSlot);
});

templates.set("fullscreen", (document, params) => {

    const newSlot = new Y.Map();
    const id =  uuid();
    newSlot.set("id",id);
    newSlot.set("x", 0);
    newSlot.set("y", 0);        
    newSlot.set("width", 900);
    newSlot.set("height", 600);
    
    document.slots.set(id, newSlot);

    document.settings.set("sizeToFit", true);
});

templates.set("cameras", (document, params) => {

    const width = 900/2;
    const height = 600/2;

    const slot1 = document.addSlot({ x: 0, y: 0, width, height });
    const slot2 = document.addSlot({ x: width, y: 0, width, height });
    const slot3 = document.addSlot({ x: 0, y: height, width, height });
    const slot4 = document.addSlot({ x: width, y: height, width, height });

    document.addToShelf({ 
        id: "screen1", 
        slot: slot1.get("id"), 
        cameraType: "camera", 
        type: "speaker", 
        speakerIndex: 0,
    });

    document.addToShelf({ 
        id: "screen2", 
        slot: slot2.get("id"), 
        cameraType: "camera", 
        type: "speaker",
        speakerIndex: 1, 
    });

    document.addToShelf({ 
        id: "screen3", 
        slot: slot3.get("id"), 
        cameraType: "camera", 
        type: "speaker", 
        speakerIndex: 2,
    });

    document.addToShelf({ 
        id: "screen4", 
        slot: slot4.get("id"),
        cameraType: "camera", 
        type: "speaker", 
        speakerIndex: 3,
    });

    document.settings.set("sizeToFit", true);
});

templates.set("notes", (document, params) => {

   
    const slot1 = document.addSlot({ x: 0, y: 0, width: 900, height: 600 });

    const sticky = document._root.addSticky("Write your thoughts...");
    sticky.x = 0;
    sticky.y = 0;
    sticky.width = 900;
    sticky.height = 600;
    sticky.padding = 15;

    console.log("Adding board to shelf "+document.id);

    document.addToShelf( { 
        id: sticky.id, 
        slot: slot1.get("id"),
        boardID: document.id, 
        type: "board", 
        layerID: sticky.id, 
        layerType: "sticky", 
        layerInstanceID: sticky.instanceID 
    });
      
});

templates.set("screenshare", (document, params) => {
    const slot1 = document.addSlot({ x: 0, y: 0, width: 150, height: 600 });
    document.addToShelf({ 
        id: "grid", 
        slot: slot1.get("id"), 
        cameraType: "grid", 
        type: "speaker", 
        speakerIndex: 0,
    });

    const slot2 = document.addSlot({ x: 150, y: 0, width: 450, height: 600, fit: "contain" });

    document.addToShelf({ 
        id: "screen1", 
        slot: slot2.get("id"), 
        cameraType: "screen", 
        type: "speaker", 
        speakerIndex: 0,
    });

    document.settings.set("sizeToFit", true);
});


templates.set("cameras", (document, params) => {
    const slot = document.addSlot({ x: 0, y: 0, width: 900, height: 600, fit: "contain" });

    document.addToShelf({ 
        id: "screen1", 
        slot: slot.get("id"), 
        cameraType: "grid", 
        type: "speaker", 
    });

    

    document.settings.set("sizeToFit", true);
});


const ResetShelf = Symbol("ResetShelf");
export class ResetShelfCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ResetShelf, text: "Reset Shelf", ...options });     
        this.const("template", options.template);
        this.const("parameters", options.parameters);
    }

    executeTransaction(ctx) {        
        this.object.shelf.clear();
        this.object.slots.clear();

        
        if(this.template) {
            templates.get(this.template)(this.object, this.parameters);
        }
                
    }
}


const MergeSlots = Symbol("MergeSlots");
export class MergeSlotsCommand extends ObjectCommand {
    constructor(options) {
        super({ id: MergeSlots, text: "Merge Slots", ...options });
        this.const("slots", options.slots);        
    }

    executeTransaction(ctx) {
        const slots = this.slots.map(s => this.object.slots.get(s));
        const shelf = this.object.getSortedShelf();
        
        for(var a of slots) {
            var ax = a.get("x");
            var ay = a.get("y");
            var aw = a.get("width");
            var ah = a.get("height");
            var ar = ax + aw;
            var ab = ay + ah;
            

            for(var b of slots) { 
                var bx = b.get("x");
                var by = b.get("y");
                var bw = b.get("width");
                var bh = b.get("height");

                var br = bx + bw;
                var bb = by + bh;
                            
                
                // a bottom = b top
                if(ax == bx && ab == by && ar == br) {
                    const existing = shelf.filter((x) => x.get("slot") == b.get("id"));
                    existing.forEach((x) => {
                        x.set("slot",a.get("id"));
                    });

                    this.object.slots.delete(b.get("id"));
                    a.set("height", ah + bh);
                }

                // a right = b left
                if(ay == by && ar == bx && ab == bb) {
                    const existing = shelf.filter((x) => x.get("slot") == b.get("id"));
                    existing.forEach((x) => {
                        x.set("slot",a.get("id"));
                    });

                    this.object.slots.delete(b.get("id"));
                    a.set("width", aw + bw);
                }
            }
        }
    }
}

const RemoveSlots = Symbol("RemoveSlots");
export class RemoveSlotsCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveSlots, text: "Remove Slots", ...options });
        this.const("slots", options.slots);        
    }

    executeTransaction(ctx) {
        const slots = this.slots.map(s => this.object.slots.get(s));                
        for(var a of slots) {
            this.object.slots.delete(a.get("id"));         
        }
    }
}

const AddShelfColumn = Symbol("AddShelfColumn");
export class AddShelfColumnCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddShelfColumn, text: "Add Column", ...options });
        this.const("slotID", options.slotID);        
        this.const("width", options.width);        
    }

    executeTransaction(ctx) {
        var maxX = 0;
        var maxY = 0;

        for(var slot of this.object.slots.values()) {
            maxX = Math.max(slot.get("x") + slot.get("width"), maxX);
            maxY = Math.max(slot.get("y") + slot.get("height"), maxY);
        }

        const newSlot = new Y.Map();
        const id = this.slotID || uuid();

        newSlot.set("id", id);
        newSlot.set("x", maxX);
        newSlot.set("y", 0);
        newSlot.set("height", maxY);
        newSlot.set("width", this.width || 1400);

        this.object.slots.set(id, newSlot);
    }
}


const AddShelfRow = Symbol("AddShelfRow");
export class AddShelfRowCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddShelfRow, text: "Add Row", ...options });
        this.const("slotID", options.slotID);        
        this.const("height", options.height);        
    }

    executeTransaction(ctx) {
        var maxX = 0;
        var maxY = 0;

        for(var slot of this.object.slots.values()) {
            maxX = Math.max(slot.get("x") + slot.get("width"), maxX);
            maxY = Math.max(slot.get("y") + slot.get("height"), maxY);
        }

        const newSlot = new Y.Map();
        const id = this.slotID || uuid();

        newSlot.set("id", id);
        newSlot.set("x", 0);
        newSlot.set("y", maxY);
        newSlot.set("height", this.height || 600);
        newSlot.set("width", maxX);

        this.object.slots.set(id, newSlot);
    }
}


const AddShelfSlot = Symbol("AddShelfSlot");
export class AddShelfSlotCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddShelfSlot, text: "Add Slot", ...options });
        this.const("slotID", options.slotID);  
        this.const("x", options.x);  
        this.const("y", options.y);  
        this.const("width", options.width);        
        this.const("height", options.height);        
    }

    executeTransaction(ctx) {
   
        const newSlot = new Y.Map();
        const id = this.slotID || uuid();

        newSlot.set("id", id);
        newSlot.set("x", this.x || 0);
        newSlot.set("y", this.y || 0);
        newSlot.set("height", this.height || 600);
        newSlot.set("width", this.width || 600);

        this.object.slots.set(id, newSlot);
    }
}



const VerticalSplitShelf = Symbol("VerticalSplitShelf");
export class VerticalSplitShelfCommand extends ObjectCommand {
    constructor(options) {
        super({ id: VerticalSplitShelf, text: "Vertical Split Shelf", ...options });
        this.const("slot", options.slot);        
    }

    executeTransaction(ctx) {
        const slot = this.object.slots.get(this.slot);
        const newHeight = slot.get("height") / 2;
        slot.set("height", newHeight);

        const newSlot = new Y.Map();
        const id = uuid();
        newSlot.set("id", id);
        newSlot.set("x", slot.get("x"));
        newSlot.set("y", slot.get("y") + newHeight);
        newSlot.set("height", newHeight);
        newSlot.set("width", slot.get("width"));
        newSlot.set("kind", slot.get("kind"));
        this.object.slots.set(id, newSlot);
    }
}

const HorizontalSplitShelf = Symbol("HorizontalSplitShelf");
export class HorizontalSplitShelfCommand extends ObjectCommand {
    constructor(options) {
        super({ id: HorizontalSplitShelf, text: "Horizontal Split Shelf", ...options });
        this.const("slot", options.slot);        
    }

    executeTransaction(ctx) {
        const slot = this.object.slots.get(this.slot);
        const newWidth = slot.get("width") / 2;
        slot.set("width", newWidth);

        const newSlot = new Y.Map();
        const id = uuid();
        newSlot.set("id", id);
        newSlot.set("x", slot.get("x") + newWidth);
        newSlot.set("y", slot.get("y"));        
        newSlot.set("width", newWidth);
        newSlot.set("height", slot.get("height"));
        newSlot.set("kind", slot.get("kind"));

        this.object.slots.set(id, newSlot);
    }
}


const MergeShelf = Symbol("MergeShelf");
export class MergeShelfCommand extends ObjectCommand {
    constructor(options) {
        super({ id: MergeShelf, text: "Merge Shelf", ...options });
        this.const("slots", options.slots);
    }

    executeTransaction(ctx) {
        const slots = this.slots.map(slot => this.object.getShelfSlot(slot));
        
        var minX = slots[0].get("x");
        var minY = slots[0].get("y");

        var maxX = slots[0].get("x") + slots[0].get("width");
        var maxY = slots[0].get("y") + slots[0].get("height");

        for(var slot of slots) {
            minX = min(slot.get("x"));
            minY = min(slot.get("y"));
    
            maxX = max(maxX, slot.get("x") + slot.get("width"));
            maxY = max(maxY, slot.get("y") + slot.get("height"));                

            this.object.slots.delete(slot.get("id"));
        }

        const newSlot = new Y.Map();
        newSlot.set("id", uuid());
        newSlot.set("x", minX);
        newSlot.set("y", minY);        
        newSlot.set("width", maxX - minX);
        newSlot.set("height", maxY - minY);
        this.object.slots.set(newSlot.get("id"), newSlot);
    }
}


const AddToShelf = Symbol("AddToSelf");
export { AddToShelf }

export class AddToShelfCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddToShelf, text: "Add to Shelf", ...options });
        this.const("name", options.name);
        this.const("presenter", options.presenter);
        this.const("recording", options.recording);
        this.const("board", options.board);
        this.const("duration", options.duration);
        this.const("layerID", options.layerID);
        this.const("layerInstanceID", options.layerInstanceID);
        this.const("layerType", options.layerType);
        this.const("cameraType", options.cameraType);
        this.const("slot", options.slot);
        this.const("speakerIndex", options.speakerIndex);
        this.const("keepExisting", options.keepExisting);
        this.const("recordingKind", options.recordingKind);
    }

    executeTransaction(ctx) {
        if(this.recording) {
            this.object.addToShelf( { id: this.recording.id, keepExisting: this.keepExisting, slot: this.slot, videoID: this.recording.videoID, timelineID: this.recording.timelineID, name: this.name, type: "recording", presenter: this.presenter, duration: this.duration, recordingKind: this.recording.kind });
        } else if (this.board) {
            this.object.addToShelf( { id: this.layerID || this.board.id,  keepExisting: this.keepExisting, slot: this.slot,boardID: this.board.id, name: this.name, type: "board", layerID: this.layerID, presenter: this.presenter, duration: this.duration, layerType: this.layerType, layerInstanceID: this.layerInstanceID });
        } else {
            this.object.addToShelf( { id: this.presenter ? (this.presenter.id + "/" + this.cameraType) : uuid(),  keepExisting: this.keepExisting, slot: this.slot, cameraType: this.cameraType, name: this.name, type: "speaker", layerID: this.layerID, presenter: this.presenter, duration: this.duration, layerType: this.layerType, speakerIndex: this.speakerIndex });
        }
    }
}


const RenameShelfItem = Symbol("RenameShelfItem");
export { RenameShelfItem }

export class RenameShelfItemCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RenameShelfItem, text: "Rename shelf item", ...options });
        this.const("itemID", options.itemID);
        this.const("name", options.name);
        //this.const("afterID", options.afterID);
    }

    executeTransaction(ctx) {
        const shelf = this.object.shelf;
        const item = shelf.get(this.itemID);
        if(item) {
            item.set("name", this.name);
        } 
    }
}


const SetSlotKind = Symbol("SetSlotKind");
export { SetSlotKind }

export class SetSlotKindCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetSlotKindCommand, text: "Set Slot Kind", ...options });
        this.const("kind", options.kind);
        this.const("slot", options.slot);
        //this.const("afterID", options.afterID);
    }

    executeTransaction(ctx) {
        const slot = this.object.slots.get(this.slot);
        if(slot) {
            slot.set("kind", this.kind);
        } 
    }
}




const RepositionShelfItem = Symbol("RepositionShelfItem");
export { RepositionShelfItem }

export class RepositionShelfItemCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RepositionShelfItem, text: "Reposition Shelf Item", ...options });
        this.const("slot", options.slot);       
        this.const("itemID", options.itemID);
        this.const("keepExisting", options.keepExisting);
        //this.const("afterID", options.afterID);
    }

    executeTransaction(ctx) {
       
       /* const shelf = this.object.getSortedShelf();
       const item = shelf.find((x) => x.get("id") == this.itemID);
       let next;
       let before;
       if(this.afterID) {
            const i = shelf.findIndex((x) => x.get("id") == this.afterID);            
            before = shelf[i];
            next = shelf[i + 1];
       } else {
            next = shelf[0];
       }
       item.set("pos", between(before ? before.get("pos") : START, next ? next.get("pos") : END));*/

       const shelf = this.object.getSortedShelf();

       const item = shelf.find((x) => x.get("id") == this.itemID);

       if(item) {

            if(!this.keepExisting) {
                const existing = shelf.filter((x) => x.get("slot") == this.slot && x.get("id") != this.itemID);
                existing.forEach((x) => {
                    x.set("slot", item.get("slot"));
                //    this.object.shelf.delete(x.get("id"));
                });
            }

            
            item.set("slot", this.slot);
        }
    }
}


const RemoveFromShelf = Symbol("RemoveFromShelf");
export { RemoveFromShelf }

export class RemoveFromShelfCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveFromShelf, text: "Remove from Shelf", ...options });
        this.const("itemID", options.itemID);    }

    executeTransaction(ctx) {
        this.object.removeFromShelf(this.itemID);        
    }
}

const ChangeBoxFit = Symbol("ChangeBoxFit");
export { ChangeBoxFit }

export class ChangeBoxFitCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ChangeBoxFit, text: "Change Box Fit", ...options });
        this.const("slot", options.slot);    
        this.const("fit", options.fit);    
    }

    executeTransaction(ctx) {
        const slot = this.object.slots.get(this.slot);
        if(!slot) {
            throw new Error(`Missing slot ${this.slot}`);
        }
        slot.set("fit", this.fit);
    }
}

const ChangeSlotPlaceholder = Symbol("ChangeSlotPlaceholder");
export { ChangeSlotPlaceholder }

export class ChangeSlotPlaceholderCommand extends ObjectCommand {
    constructor(options) {
        super({ id: ChangeSlotPlaceholder, text: "Change Slot Placeholder", ...options });
        this.const("slot", options.slot);    
        this.const("placeholder", options.placeholder);    
    }

    executeTransaction(ctx) {
        const slot = this.object.slots.get(this.slot);
        if(!slot) {
            throw new Error(`Missing slot ${this.slot}`);
        }
        slot.set("placeholder", this.placeholder);
    }
}

const RemoveRecentChat= Symbol("RemoveRecentChat");
export { RemoveRecentChat }

export class RemoveRecentChatCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveRecentChat, text: "Remove Recent Contact", ...options });
        this.const("chatID", options.chatID);
     }

    executeTransaction(ctx) {        
        this.object._y.getMap("recentChats").delete(this.chatID);        
    }
}


const AddActiveChat = Symbol("AddActiveChat");
export { AddActiveChat }

export class AddActiveChatCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddActiveChat, text: "Add Recent", ...options });
        this.const("chatID", options.chatID);
        this.const("timestamp", options.timestamp);
        this.const("ifNotFound", options.ifNotFound)
    }

    executeTransaction(ctx) {
        if(!this.ifNotFound || !this.object._y.getMap("activeChats").get(this.chatID)) {
            this.object._y.getMap("activeChats").set(this.chatID, this.timestamp);
        }
    }
}

const RemoveActiveChat= Symbol("RemoveRecentChat");
export { RemoveActiveChat }

export class RemoveActiveChatCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveActiveChat, text: "Remove Active Contact", ...options });
        this.const("chatID", options.chatID);
     }

    executeTransaction(ctx) {        
        this.object._y.getMap("activeChats").delete(this.chatID);        
    }
}


const AddRecentContact = Symbol("AddRecentContact");
export { AddRecentContact }

export class AddRecentContactCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddRecentContact, text: "Add Recent", ...options });
        this.const("id", options.id);
        this.const("timestamp", options.timestamp);
        this.const("ifNotFound", options.ifNotFound)
    }

    executeTransaction(ctx) {
        if(!this.ifNotFound || !this.object._y.getMap("recentContacts").get(this.id)) {
            this.object._y.getMap("recentContacts").set(this.id, this.timestamp);
        }
    }
}

const RemoveRecentContact= Symbol("RemoveRecentContact");
export { RemoveRecentContact }

export class RemoveRecentContactCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveRecentContact, text: "Remove Recent Contact", ...options });
        this.const("contactID", options.contactID);
     }

    executeTransaction(ctx) {        
        this.object._y.getMap("recentContacts").delete(this.contactID);        
    }
}

const AddRecentRoom = Symbol("AddRecentRoom");
export { AddRecentRoom }

export class AddRecentRoomCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddRecentRoom, text: "Add Recent", ...options });
        this.const("roomID", options.roomID);
        this.const("pos", options.pos);
        this.const("name", options.name || null);
        this.const("ifNotFound", options.ifNotFound);
        this.const("kind", options.kind || null);
    }

    executeTransaction(ctx) {
        if(!this.ifNotFound || !this.object._y.getMap("recentRooms").get(this.roomID)) {            
            const val = new Y.Map();
            val.set("name", this.name);
            val.set("pos", this.pos ?? between(START, this.object.getRecentRooms()[0] || END));
            val.set("kind", this.kind);
            this.object._y.getMap("recentRooms").set(this.roomID, val);
        }
    }
}

const AddRecentBoard = Symbol("AddRecentBoard");
export { AddRecentBoard }

export class AddRecentBoardCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddRecentBoard, text: "Add Recent", ...options });
        this.const("boardID", options.boardID);
        this.const("pos", options.pos);
        this.const("name", options.name);
        this.const("ifNotFound", options.ifNotFound)
    }

    executeTransaction(ctx) {
        if(!this.ifNotFound || !this.object._y.getMap("recentBoards").get(this.boardID)) {
            const r = new Y.Map();
            const last = this.object.getRecentBoardEntries()[0];
            r.set("pos",  this.pos || between(last != null ? (last[1].get("pos") ?? START) : START, END));
            r.set("name", this.name);
            this.object._y.getMap("recentBoards").set(this.boardID, r);
        }
    }
}


const AddRecentRecording = Symbol("AddRecentRecording");
export { AddRecentRecording }

export class AddRecentRecordingCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddRecentRecording, text: "Add Recent Recording", ...options });
        this.const("name", options.name);
        this.const("createdBy", options.createdBy);
        this.const("createdAt", options.createdAt);
        this.const("recordingID", options.recordingID);
        this.const("kind", options.kind);
    }

    executeTransaction(ctx) {
        const recording = new Y.Map();
        recording.set("kind", this.kind);
        recording.set("name", this.name);
        recording.set("createdBy", this.createdBy);
        recording.set("id", this.recordingID);
        recording.set("createdAt", this.createdAt || new Date().toISOString());
        this.object._y.getArray("recentRecordings").push([ recording ]);
    }
}

const RemoveRecentRecording = Symbol("RemoveRecentRecording");
export { RemoveRecentRecording }

export class RemoveRecentRecordingCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveRecentRecording, text: "Remove Recent Recording", ...options });
        this.const("recordingID", options.recordingID);
    }

    executeTransaction(ctx) {
        const recordings = this.object._y.getArray("recentRecordings");
        for(let i = 0; i < recordings.length; i++) {
            const r = recordings[i];
            if(r.get("id") == this.recordingID) {
                recordings.delete(i);
                break;
            }
        }
    }
}



const PinRecentBoard = Symbol("PinRecentBoard");
export { PinRecentBoard }

export class PinRecentBoardCommand extends ObjectCommand {
    constructor(options) {
        super({ id: PinRecentBoard, text: "Pin Recent", ...options });
        this.const("boardID", options.boardID);
        this.const("order", options.order);        
    }

    executeTransaction(ctx) {        
        this.object._y.getMap("pinnedBoards").set(this.boardID, this.order);
    }
}


const RemoveRecentBoard = Symbol("RemoveRecentBoard");
export { RemoveRecentBoard }

export class RemoveRecentBoardCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveRecentBoard, text: "Remove Recent", ...options });
        this.const("boardID", options.boardID);
     }

    executeTransaction(ctx) {        
        this.object._y.getMap("recentBoards").delete(this.boardID);        
    }
}

const RemoveRecentRoom = Symbol("RemoveRecentRoom");
export { RemoveRecentRoom }

export class RemoveRecentRoomCommand extends ObjectCommand {
    constructor(options) {
        super({ id: RemoveRecentRoom, text: "Remove Recent", ...options });
        this.const("roomID", options.roomID);
     }

    executeTransaction(ctx) {        
        this.object._y.getMap("recentRooms").delete(this.roomID);        
    }
}

const DeleteContact = Symbol("DeleteContact");
export { DeleteContact }

export class DeleteContactCommand extends ObjectCommand {
    constructor(options) {
        super({ id: DeleteContact, text: "delete Contact", ...options });
        this.const("id", options.id);
    }

    executeTransaction(ctx) {
        const contacts = this.object._y.getMap("contacts");
        contacts.delete(this.id);
    }
}

const UpdateContact = Symbol("UpdateContact");
export { UpdateContact }

export class UpdateContactCommand extends ObjectCommand {
    constructor(options) {
        super({ id: UpdateContact, text: "Update Contact", ...options });
        this.const("contactID", options.contactID);
        this.const("createdAt", options.createdAt);
        this.const("firstName", options.firstName);
        this.const("lastName", options.lastName);
        this.const("email", options.email);
        this.const("mobilePhone", options.mobilePhone);
    }

    executeTransaction(ctx) {
        const contact = this.object._y.getMap("contacts").get(this.contactID);  
        if(typeof(this.createdAt) == "string") {
            contact.set("createdAt", this.createdAt);
        }
        if(typeof(this.firstName) == "string") {
            contact.set("firstName", this.firstName);
        }
        if(typeof(this.lastName) == "string") {
            contact.set("lastName", this.lastName);
        }
        if(typeof(this.email) == "string") {
            contact.set("email", this.email);
        }
        if(typeof(this.mobilePhone) == "string") {
            contact.set("mobilePhone", this.mobilePhone);
        }
    }
}


const SetReadOnly = Symbol("SetReadOnly");
export { SetReadOnly }


export class SetReadOnlyCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetReadOnly,  ...options });

        switch(options.readOnlyTo) {
            case "everyone":
            case "members":
            case "guests":
            case "none":
                break;
            default:
                throw Error("Invalid value for readOnlyTo")
        }

        this.const("readOnlyTo", options.readOnlyTo);        

        
    }

    executeTransaction(ctx) {
        this.object.settings.set("readOnlyTo", this.readOnlyTo);
    }
}


const SetSizeToFit = Symbol("SetSizeToFit");
export { SetSizeToFit }

export class SetSizeToFitCommand extends ObjectCommand {
    constructor(options) {
        super({ id: SetSizeToFit,  ...options });
        this.const("sizeToFit", options.sizeToFit);
    }

    executeTransaction(ctx) {
        this.object.settings.set("sizeToFit", this.sizeToFit);
    }
}


function setDefaultSceneDimensions(scene) {
    scene.width = 1920;
    scene.height = scene.width * 9 / 16;
}

function setupCams(scene) {
    const cam = scene.addCameraGrid();
    cam.fixed = true;
    cam.x = 50;
    cam.y = 50;
    cam.width = 300;
    cam.height = scene.height - 100;
    cam.bringToFront();
    cam.deselect();
}



const AddScene = Symbol("AddScene");
export { AddScene }

export class AddSceneCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddScene, text: "Canvas", ...options });
        this.const("passive", options.passive);
        this.const("newInstanceID", options.newInstanceID);
        this.const("newObjectID", options.newObjectID);
        this.const("createdBy", options.createdBy);
        this.const("createdAt", options.createdAt);
        this.const("name", options.name);
        this.const("mode", options.mode);
        this.const("width", options.width);
        this.const("height", options.height);
        this.const("index", options.index);
    }

    executeTransaction(ctx) {
        const scene = this.object.addScene({ id: this.newObjectID, mode: this.mode, instanceID: this.newInstanceID, createdBy: this.createdBy, name: this.name, createdAt: this.createdAt });
        setDefaultSceneDimensions(scene);
        scene.yAlign = "top";
        scene.xAlign = "left";

        if(this.width) {
            scene.width = this.width;
        }

        if(this.height) {
            scene.height = this.height;
        }
      
        scene.name = this.name || "Canvas";
        //setupCams(scene);

        if(typeof(this.index) !== "undefined") {
            scene.changePosition(this.index);
        }
    }
}

export class AddScreenCommand extends ObjectCommand {
    constructor(options) {
        super({ id: AddScreenCommand, text: "Screen", ...options });
        
        this.const("videoTrack", options.videoTrack);
        this.const("participant", options.participant);
        this.const("instanceID", options.instanceID);
        this.const("width", options.width);
        this.const("height", options.height);
        this.const("createdBy", options.createdBy);
        this.const("createdAt", options.createdAt);
    }

    executeTransaction(ctx) {
        const scene = this.object.addScreen({ instanceID: this.instanceID, createdBy, name: this.createdBy, createdAt: this.createdAt });

        scene.name = "Screen";
        scene.width = this.width;
        scene.height = this.height;
        scene.videoTrack = this.videoTrack;
        scene.participant = this.participant;

    }
}

export const SetLocalStateField = Symbol("SetLocalStateField");

export class SetLocalStateFieldCommand extends ObjectCommand {
    constructor(options) {
        super(options);
        this.const("field", options.field);
        this.const("value", options.value);
    }

    execute(ctx) {
        this.object.awareness.setLocalStateField(this.field, this.value);
    }
}





